import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import i18next from "i18next";
import "../../css/style.css";
import "../../css/Pages/EntityPage.css";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { EntityPage } from "./EntityPage";
import { userService } from "../../_services";
import { store, clearDate } from "../../_helpers";
import { userActions } from "../../_actions";
import TabEditor from "../TabEditor";
import ElogDateTime from "../ElogDateTime";

class UpsertDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: props.dialogFields,
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.dialogFields !== prevProps.dialogFields) {
      this.setState({
        fields: this.props.dialogFields,
      });
    }
  }

  saveData = (e) => {
    e.preventDefault();

    for (let field in this.state.fields) {
      if (
        this.state.fields[field].required &&
        !this.state.fields[field].value
      ) {
        return;
      }
    }
    this.closeDialog(this.state.fields);
  };

  cancelData = () => {
    this.props.closer();
  };

  text = (item) => {
    const options = this.state.fields[item].options
      ? this.state.fields[item].options
      : { multiline: false };
    return (
      <TextField
        autoFocus
        margin="dense"
        id={item}
        key={item}
        label={
          this.state.fields[item].label ? this.state.fields[item].label : item
        }
        fullWidth
        variant="standard"
        value={this.state.fields[item].value}
        required={this.state.fields[item].required}
        multiline={options.multiline}
        onChange={e => {
          this.state.fields[item].value = e.target.value !== EntityPage.NULL_FILLER ? e.target.value : null;
          this.setState({ fields: this.state.fields });
        }}
      />
    );
  };

  editor = item => {
    const itemProps = this.state.fields[item];
    const label = itemProps.label ? itemProps.label : item;
    return <div className="UpsertDialog_Editor">
      <span className="form__label">{label}</span>
      <TabEditor
        onChange={(val, tabName) => {
          this.state.fields[item].value = val !== EntityPage.NULL_FILLER ? val : null;
          this.setState({ fields: this.state.fields });
        }}
        value={this.state.fields[item].value}
        langList={[]}
        isMultilang={false}
      />
    </div>
  };

  hidden = (item) => {
    return (
      <input type="hidden" id={item} value={this.state.fields[item].value} />
    );
  };

  select = (item, isMultiSelect) => {
    const itemProps = this.state.fields[item];
    const options = itemProps.options;
    const label = itemProps.label ? itemProps.label : item;
    const subdialog = itemProps.subdialog ? itemProps.subdialog : null;
    return (
      <div className="UpsertDialog_Select">
        <span className="form__label">{label}</span>
        <Select
          disabled={isMultiSelect && Object.entries(options).length === (this.state.fields[item].value ?? []).length}
          margin="dense"
          id={item}
          value={itemProps.value || EntityPage.NULL_FILLER}
          label={label}
          fullWidth
          required={itemProps.required}
          onChange={e => {
            const value = e.target.value;
            if (isMultiSelect) {
              if (value === EntityPage.NULL_FILLER) {
                return;
              }
              if (this.state.fields[item].value === null) {
                this.state.fields[item].value = [];
              }
              this.state.fields[item].value.push(value);
            } else {
              this.state.fields[item].value = value !== EntityPage.NULL_FILLER ? value : null;
            }
            this.setState({ fields: this.state.fields });
          }}
        >
          {Object.entries(options).length !== 0 &&
            Object.keys(options).map((optionKey, index) => (
              (!isMultiSelect || (this.state.fields[item].value ?? []).indexOf(optionKey) === -1) && <MenuItem key={optionKey} value={optionKey}>{options[optionKey]}</MenuItem>
            ))}
        </Select>
        {isMultiSelect && (this.state.fields[item].value ?? []).map((value, index) => <div>
          {options[value]}
          <Button onClick={() => {
            if (this.state.fields[item].value.length > 1) {
              this.state.fields[item].value.splice(index, 1);
            } else {
              this.state.fields[item].value = null;
            }
            this.setState({ fields: this.state.fields });
          }}>X</Button>
        </div>)}
        {subdialog && subdialog(itemProps.value, this.props.id)}
      </div>
    );
  };

  datetime = item => {
    const itemProps = this.state.fields[item];
    const label = itemProps.label ? itemProps.label : item;
    return <div className="UpsertDialog_Editor">
      <span className="form__label">{label}</span>
      {itemProps.sublabel && <div>{itemProps.sublabel}</div>}
      <ElogDateTime
        selected={clearDate(this.state.fields[item].value ?? '')}
        handleChange={val => {
          this.state.fields[item].value = (val !== '' && val !== "0000-00-00 00:00") ? val : null;
          this.setState({ fields: this.state.fields });
        }}
      />
    </div>
  };

  showField = (item) => {
    switch (this.state.fields[item].type) {
      case "text":
        return this.text(item);
      case "editor":
        return this.editor(item);
      case "hidden":
        return this.hidden(item);
      case "select":
        return this.select(item, false);
      case "multiselect":
        return this.select(item, true);
      case "datetime":
        return this.datetime(item);
      default:
    }
  };

  closeDialog = (fields = null) => {
    let postData = {};
    if (this.props.getPostData) {
      postData = this.props.getPostData(this.props.id, fields);
    } else {
      for (let field in fields) {
        postData[field] = fields[field].value;
      }
    }

    store.dispatch(userActions.loading(true));
    if (this.props.id === 0) {
      userService.postWrapper(
        this.props.endpoints.add,
        postData,
        (data) => {
          this.props.loader();
        },
        (error) => {
          store.dispatch(userActions.loading(false));
          if (error !== "") {
            this.setState({ error });
            alert(error);
          }
        }
      );
    } else {
      const editEndpoint = this.props.endpoints.edit instanceof Function ? this.props.endpoints.edit() : `${this.props.endpoints.edit}/${this.props.id}`;
      const editMethod = this.props.endpoints.editMethod ? this.props.endpoints.editMethod : userService.patchWrapperBody;
      editMethod(
        editEndpoint,
        postData,
        (data) => {
          this.props.loader();
        },
        (error) => {
          store.dispatch(userActions.loading(false));
          if (error !== "") {
            this.setState({ error });
            alert(error);
          }
        }
      );
    }
    this.props.closer();
  };

  render() {
    return (
      <Dialog open={this.props.dialogOpen}>
        <DialogTitle>{this.props.title}</DialogTitle>
        <form onSubmit={this.saveData}>
          <DialogContent>
            {Object.entries(this.state.fields).length !== 0 &&
              Object.keys(this.state.fields).map((item, index) =>
                this.showField(item)
              )}
          </DialogContent>
          <DialogActions>
            <Button type="submit">{i18next.t("btn_save")}</Button>
            <Button onClick={this.cancelData}>{i18next.t("btn_cancel")}</Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

export { UpsertDialog };
