import React from "react";
import "../css/style.css";
import { connect } from "react-redux";
import { store } from "../_helpers";
import SiteItem from "./SiteItem";
import { userService, confService } from "../_services";
import Button from "@material-ui/core/Button";

import { userActions, appActions } from "../_actions";

class RentListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false, start_date:null,end_date:null, sites:[] };
    store.subscribe(this.storeChange);
  }
  
  

  componentDidMount() {
      
     //this.props.dispatch(userActions.site_list());
	 let self = this;
	 this.getSiteList();
  }
  
	filterSites  = () => {
		
		const { start_date,end_date } = this.state;
		
		console.log(start_date,end_date);
		
		 let self = this;
	  
	  confService.siteListRent(function(data)
	 {
		 
		 self.setState({sites:[...data.data]});
	 },
	 function(err)
	 {
		 console.log(err);
	 },start_date,end_date);
		
	
	}
  
  getSiteList = () => {
	  
	  
	  let self = this;
	  
	  confService.siteListRent(function(data)
	 {
		 console.log(data.data);
		 self.setState({sites:[...data.data]});
	 },
	 function(err)
	 {
		 console.log(err);
	 },null,null);
	  
  }
  

  storeChange = (e) => {};

  onItemClick = (e) => {
	  
	  
	 this.props.dispatch(userActions.set_site(e));
    this.props.dispatch(appActions.setpage("site_view", e.id));
	
  };

  changeDate = (index, event) => {
    
    if (index == 0) this.setState({ start_date: event.target.value });
    else this.setState({ end_date: event.target.value });
  };

  

  render() {
    const self = this;
    const { isLoading, start_date, end_date, sites } = this.state;
    const { classes, user } = this.props;
    
    console.log(start_date,user.conflist);
    
    return (
      <div className="layout">
        <div className="layout__contains" style={{justifyContent: 'flex-start'}}>
          <h1 className="layout__title">Площадки</h1>
          
          <div style={{width: 300, display:'flex',flexDirection:'row'}}>
            
           <div className="date-wrap form__group-row">
                           <label htmlFor="start">даты с</label>
                           <input
                            type="date"
                            className="form__input"
                            id="start"
                            value={start_date}
                            name="trip-start"
                            onChange={(e) => this.changeDate(0, e)}
                          />
                        </div>
                        
                          <div className="date-wrap form__group-row">
                          <label htmlFor="start"> до</label>
                          <input
                            type="date"
                            className="form__input"
                            id="start"
                            value={end_date}
                            name="trip-start"
                            onChange={(e) => this.changeDate(1, e)}
                          />
                        </div>
						<Button onClick={self.filterSites}>
						Поиск
						</Button>
          </div>

        </div>

        <ul className="conf-list">
          {sites.length > 0 &&
            sites
              .map((item, index) => (
                <SiteItem
                  data={item}
                  key={index}
                  onClick={() => self.onItemClick(item)}
                  
                />
              ))}
        
             
        </ul>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;

  return {
    user,
    app,
    authentication,
  };
}

const connectedRentListPage = connect(mapStateToProps)(RentListPage);
export { connectedRentListPage as RentListPage };
