import * as React from 'react';
import i18next from 'i18next';
import { serverUrl } from "../_constants";
import Dropzone from "react-dropzone";
import classNames from "classnames";

export default function DropFile(props) {
  return (<div className="file">
    <Dropzone
      onDrop={props.onDrop}
      class="visually--hidden"
    >
      {({
        getRootProps,
        getInputProps,
        isDragActive,
      }) => {
        return (
          <div
            style={{
              textAlign: "center",
              verticalAlign: "center",
              width: 250,
              height: 250,
              background: "#eee",
              borderRadius: 5,
            }}
            {...getRootProps()}
            className={classNames("dropzone", {
              "dropzone--isActive": isDragActive,
            })}
          >
            <input {...getInputProps()} />

            {props.image && (
              <img
                src={(props.image.startsWith("blob") ? "" : serverUrl) + props.image}
                alt=""
                class="object-cover"
              />
            )}
            {isDragActive ? (
              <p
                style={{
                  fontSize: 12,
                  position: "absolute",
                  top: 110,
                  left: 15,
                }}
              >
                {i18next.t("dropzone_file_upload")}
              </p>
            ) : (
              <p
                style={{
                  fontSize: 12,
                  position: "absolute",
                  top: 110,
                  left: 15,
                  width: 220,
                  background: "#eee",
                  padding: 10,
                  borderRadius: 5,
                }}
              >
                {i18next.t("dropzone_title_hint")}
              </p>
            )}
          </div>
        );
      }}
    </Dropzone>
  </div>);
}
