import React from "react";
import "../css/style.css";

import { connect } from "react-redux";
import i18next from "i18next";
import { CircularProgress } from "@material-ui/core";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { confService } from "../_services";
import PartItem from "./PartItem";
import { ConferenceNotSelected } from "../_components/ConferenceNotSelected";
import { serverUrl, agrUrl, helpUrl } from "../_constants";

class PartPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      team: [],
      nameF: "",
      confF: "",
      ticketF: "",
      dateF: "",
      sortNameDesc: false,
      forStudentConference: 0,
      isOrg: false,
    };
  }

  componentDidMount() {
    this.getStudents();
    this.setState({ isOrg: this.props.user.info.type === "org" });
  }

  getStudents = () => {
    const self = this;
    const { selectedConf } = this.props.user;

    if (this.props.user.info.type === "student" && !selectedConf) {
      self.setState({ team: [], isLoading: false, forStudentConference: 0 });
    } else {
      const forStudentConference =
        this.props.user.info.type === "student" && selectedConf
          ? selectedConf.id
          : 0;
      confService.confListStudents((data) => {
        self.setState({
          team: [...data],
          isLoading: false,
          forStudentConference,
        });
      }, forStudentConference);
    }
  };

  downloadBadge = (e) => {
    confService.downloadBadge(
      e.conf_id,
      e.user_id,
      (data) => {
        if (data.data) {
          var wnd = window.open(serverUrl + data.data);
          wnd.print();
        }
      },
      (error) => {
        alert(error);
      }
    );
  };

  editFilter = (name, val) => {
    if (name === "name") this.setState({ nameF: val });
    else if (name === "conf") this.setState({ confF: val });
    else if (name === "date") this.setState({ dateF: val });
    else if (name === "ticket") this.setState({ ticketF: val });
  };

  render() {
    const self = this;
    const {
      isLoading,
      team,
      nameF,
      confF,
      dateF,
      ticketF,
      sortNameDesc,
      forStudentConference,
      isOrg,
    } = this.state;
    const forStudent = this.props.user.info.type === "student";
    if (forStudent) {
      const currentSelected =
        this.props.user.selectedConf && this.props.user.selectedConf.id
          ? this.props.user.selectedConf.id
          : 0;
      if (currentSelected != forStudentConference) {
        this.getStudents();
      }
    }

    return (
      <div className="layout">
        <div className="layout__contains">
          <h1 className="layout__title">{i18next.t("partips")}</h1>
        </div>
        {forStudent && (
          <ConferenceNotSelected page={"partips"} forStudent={true} />
        )}
        <div className="tbl">
          <div className="tbl__top">
            <div className="tbl__items">
              {isOrg && <div className="tbl__item tbl__item--city"></div>}
              <div className="tbl__item tbl__item--city">
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    onClick={() =>
                      this.setState({ sortNameDesc: !sortNameDesc })
                    }
                  >
                    {sortNameDesc ? (
                      <ExpandLess fontSize="large" />
                    ) : (
                      <ExpandMore fontSize="large" />
                    )}
                  </div>
                  <input
                    type="text"
                    placeholder={i18next.t("search_for_name")}
                    value={nameF}
                    onChange={(e) => self.editFilter("name", e.target.value)}
                  />
                </div>
              </div>
              <div className="tbl__item tbl__item--city">
                <input
                  type="text"
                  placeholder={i18next.t("search_for_event")}
                  value={confF}
                  onChange={(e) => self.editFilter("conf", e.target.value)}
                />
              </div>
              <div className="tbl__item"></div>
              {!forStudent && (
                <div className="tbl__item tbl__item--text">
                  <input
                    type="text"
                    placeholder={
                      i18next.t("search_for") +
                      " " +
                      i18next.t("search_type_ticket")
                    }
                    value={ticketF}
                    onChange={(e) => self.editFilter("ticket", e.target.value)}
                  />
                </div>
              )}
              {!forStudent && (
                <div className="tbl__item tbl__item--text">
                  <input
                    type="text"
                    placeholder={i18next.t("date_search")}
                    value={dateF}
                    onChange={(e) => self.editFilter("date", e.target.value)}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="tbl__body">
            {team.length > 0 &&
              team
                .filter(function (e) {
                  if (!e) return false;

                  return (
                    (e.user_name || "")
                      .toLowerCase()
                      .indexOf(nameF.toLowerCase()) >= 0 &&
                    e.conf_name.toLowerCase().indexOf(confF.toLowerCase()) >=
                      0 &&
                    e.updated_at.toLowerCase().indexOf(dateF.toLowerCase()) >=
                      0 &&
                    e.ticket_name
                      .toLowerCase()
                      .indexOf(ticketF.toLowerCase()) >= 0
                  );
                })
                .sort(function (a, b) {
                  var nameA = a.user_name.toLowerCase(),
                    nameB = b.user_name.toLowerCase();

                  if (!sortNameDesc) {
                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;
                    return 0;
                  } else {
                    if (nameA < nameB) return 1;
                    if (nameA > nameB) return -1;
                    return 0;
                  }
                })
                .map((item, index) => (
                  <PartItem
                    data={item}
                    forStudent={forStudent}
                    downloadBadge={() => self.downloadBadge(item)}
                    isOrg={isOrg}
                  />
                ))}
          </div>
        </div>

        {isLoading && (
          <div className="shadow">
            <div className="shadow_circle">
              <CircularProgress />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;

  return {
    user,
    app,
    authentication,
  };
}

const connectedPartPage = connect(mapStateToProps)(PartPage);
export { connectedPartPage as PartPage };
