import React from "react";
import "../css/style.css";

import { connect } from "react-redux";
import BldDialog from "../MainPage/BldDialog";
import { store } from "../_helpers";
import { confService, scheduleService, userService } from "../_services";
import BldInfo from "./BldInfo";

import { CircularProgress } from "@material-ui/core";
import i18next from "i18next";
import { userActions } from "../_actions";
import BuildingsItem from "./BuildingsItem";
import { LANG_LIST } from "../_constants";

const TableHeaderItem = ({ text }) => {
  return (
    <span
      style={{ fontSize: 16, fontWeight: 600, textDecoration: "underline" }}
    >
      {text}
    </span>
  );
};

class BuildingsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      blds: [],
      bldId: 0,
      dName: "",
      dAddress: "",
      dMail: "",
      dNameI18N: {},
      dDesc: "",
      dDescI18N: {},
      bldDialog: false,
      personId: 0,
      commentId: 0,
      slotInfoOpen: false,
    };
    store.subscribe(this.storeChange);
  }

  componentDidMount() {
    this.getBuildings();
  }

  getBuildings = () => {
    scheduleService.getRoomList();
    let self = this;

    userService.getWrapper(
      "api/building",
      (data) => {
        console.log(data);
        self.setState({ isLoading: false });
        if (data.data) {
          let rData = [...data.data];

          for (let i = 0; i < rData.length; i++) {
            if (rData[i].comments.length > 0) {
              rData[i].comment = rData[i].comments[0].text;
              rData[i].comment_id = rData[i].comments[0].id;
              rData[i].user_id = rData[i].comments[0].user_id;
            }

            if (rData[i].contacts.length > 0) {
              rData[i].person = rData[i].contacts[0].person;
              rData[i].phone = rData[i].contacts[0].phone;
              rData[i].person_id = rData[i].contacts[0].id;
              rData[i].user_id = rData[i].contacts[0].user_id;
            }
          }

          self.setState({ blds: rData });
        }
      },
      (error) => {
        if (error != "") {
          self.setState({ error, isLoading: false });
        }
        else
        {
          self.setState({ isLoading: false });
        }
      }
    );
  };

  storeChange = (e) => {};

  addBld = () => {
    this.setState({
      bldId: 0,
      dAddress: "",
      dName: "",
      dEmail: "",
      dPerson: "",
      dPhone: "",
      dComment: "",
      dDesc: "",
      dNameI18N: {},
      dDescI18N: {},
      bldDialog: true,
    });
  };

  delBld = (id) => {
    const self = this;
    if (window.confirm(i18next.t("btn_delete") + "?")) {

      self.setState({ isLoading: true });

      userService.delWrapper(
        `api/building/${id}`,
        (data) => {
          
          if (data.status == 200) self.getBuildings();
        },
        (error) => {
          if (error != "") {
            self.setState({ isLoading: false });
            self.props.dispatch(userActions.set_error(error));
          }
        }
      );
    }
  };

  editBld = (id) => {
    const { blds } = this.state;
    let bldAddr = "";
    let bldName = "";
    let bldDesc = "";
    let bldEmail = "";
    let bldPhone = "";
    let bldPerson = "";
    let bldComment = "";
    let commentId = 0;
    let personId = 0;

    let name_i18n = {};
    let desc_i18n = {};

    for (let i = 0; i < blds.length; i++) {
      if (blds[i].id == id) {
        console.log(blds[i]);
        bldAddr = blds[i].address;
        bldName = blds[i].name;
        bldDesc = blds[i].description;
        bldEmail = blds[i].email;
        bldPhone = blds[i].phone;
        bldPerson = blds[i].person;
        bldComment = blds[i].comment;
        personId = blds[i].person_id ? blds[i].person_id : 0;
        commentId = blds[i].comment_id ? blds[i].comment_id : 0;
        name_i18n = blds[i].name_i18n ?? {};
        desc_i18n = blds[i].description_i18n ?? {};

        if (!name_i18n["ru"] && bldName)
          name_i18n = { ru: bldName, en: "", cn: "" };

        if (!desc_i18n["ru"] && bldDesc)
          desc_i18n = { ru: bldDesc, en: "", cn: "" };
      }
    }

    this.setState({
      bldId: id,
      dAddress: bldAddr,
      dName: bldName,
      dDesc: bldDesc,
      dEmail: bldEmail,
      dPerson: bldPerson,
      dPhone: bldPhone,
      dComment: bldComment,
      bldDialog: true,
      commentId: commentId,
      personId: personId,
      dNameI18N: name_i18n,
      dDescI18N: desc_i18n,
    });
  };

  closeBldDialog = (val) => {
    const self = this;
    const {
      bldId,
      dName,
      dAddress,
      dEmail,
      dPerson,
      dPhone,
      dComment,
      commentId,
      personId,
      dDesc,
      dNameI18N,
      dDescI18N,
    } = this.state;
    console.log(val);

    const post_data = {};

    if (val.address) post_data.address = val.address;
    if (val.description) post_data.description = val.description;
    if (val.name) post_data.name = val.name;
    if (val.name_i18n) post_data.name_i18n = val.name_i18n;
    if (val.description_i18n) post_data.description_i18n = val.description_i18n;
    if (val.email) post_data.email = val.email;

    let isUpdate = false;

    if (val.email && confService.validateEmail(val.email) == false) {
      self.setState({ bldDialog: false });
      self.props.dispatch(
        userActions.set_error(i18next.t("enter_correct_email"), "warning")
      );
      return;
    }

    if (post_data && post_data.name) {
      if (bldId > 0) {
        isUpdate = true;
        userService.patchWrapperBody(
          `api/building/${bldId}`,
          post_data,
          (data) => {
            console.log(data);
            if (data.status == 200) self.getBuildings();
          },
          (error) => {
            {
              self.setState({ error, isLoading: false });
              self.props.dispatch(userActions.set_error(error));
            }
          }
        );
      } else {
        if (val.phone) post_data.phone = val.phone;
        if (val.person) post_data.person = val.person;
        if (val.comment) post_data.comment = val.comment;

        userService.postWrapper(
          "api/building",
          post_data,
          (data) => {
            if (data.status == 200) self.getBuildings();
          },
          (error) => {
            {
              self.setState({ error, isLoading: false });
              self.props.dispatch(userActions.set_error(error));
            }
          }
        );
      }

      this.setState({ bldDialog: false });
    }

    if (
      bldId > 0 &&
      post_data &&
      post_data.name &&
      (val.person != dPerson || val.phone != dPhone)
    ) {
      isUpdate = true;
      let cData = {
        entity_type: "building",
        entity_id: bldId,
        person: val.person,
        phone: val.phone,
      };

      if (!dPerson && !dPhone) {
        console.log(val);
        userService.postWrapper(
          "api/contact",
          cData,
          (data) => {
            if (data.status == 200) self.getBuildings();
          },
          (error) => {
            {
              self.setState({ error, isLoading: false });
              self.props.dispatch(userActions.set_error(error));
            }
          }
        );
      } else if (!val.person && !val.phone) {
        let id = personId;

        if (id > 0)
          userService.delWrapper(
            `api/contact/${id}`,
            (data) => {
              if (data.status == 200) self.getBuildings();
            },
            (error) => {
              if (error != "") {
                self.setState({ isLoading: false });
                self.props.dispatch(userActions.set_error(error));
              }
            }
          );
      } else {
        let id = personId;

        if (id > 0)
          userService.patchWrapperBody(
            `api/contact/${id}`,
            { person: val.person, phone: val.phone },
            (data) => {
              if (data.status == 200) self.getBuildings();
            },
            (error) => {
              if (error != "") {
                self.setState({ isLoading: false });
                self.props.dispatch(userActions.set_error(error));
              }
            }
          );
      }

      this.setState({ bldDialog: false });
    }

    if (bldId > 0 && post_data && post_data.name && val.comment != dComment) {
      isUpdate = true;
      let cData = {
        entity_type: "building",
        entity_id: bldId,
        text: val.comment,
      };

      if (!dComment) {
        console.log(val);
        userService.postWrapper(
          "api/comment",
          cData,
          (data) => {
            if (data.status == 200) self.getBuildings();
          },
          (error) => {
            {
              self.setState({ error, isLoading: false });
              self.props.dispatch(userActions.set_error(error));
            }
          }
        );
      } else if (!val.comment) {
        let id = commentId;

        if (id > 0)
          userService.delWrapper(
            `api/comment/${id}`,
            (data) => {
              if (data.status == 200) self.getBuildings();
            },
            (error) => {
              if (error != "") {
                self.setState({ isLoading: false });
                self.props.dispatch(userActions.set_error(error));
              }
            }
          );
      } else {
        let id = commentId;

        if (id > 0)
          userService.patchWrapperBody(
            `api/comment/${id}`,
            { text: val.comment },
            (data) => {
              if (data.status == 200) self.getBuildings();
            },
            (error) => {
              if (error != "") {
                self.setState({ isLoading: false });
                self.props.dispatch(userActions.set_error(error));
              }
            }
          );
      }

      this.setState({ bldDialog: false });
    }

    if (!isUpdate) this.setState({ bldDialog: false });
  };

  render() {
    const self = this;
    const {
      isLoading,
      blds,
      dName,
      dDesc,
      dNameI18N,
      dDescI18N,
      dAddress,
      dEmail,
      dPhone,
      dPerson,
      dComment,
      bldDialog,
      bldId,
      slotInfoOpen,
    } = this.state;
    const { classes } = this.props;
    const { rooms } = this.props.schedule;

    return (
      <div className="layout">
        <div className="layout__contains">
          <h1 className="layout__title">{i18next.t("blds")}</h1>

          <div className="layout__btn">
            <button className="btn" onClick={self.addBld}>
              {i18next.t("new_bld")}
            </button>
          </div>
        </div>

        <div className="tbl">
          <div className="tbl__top">
            <div className="tbl__items">
              <div className="tbl__item tbl__item--name">
                <TableHeaderItem text={i18next.t("title")} />
              </div>
              <div className="tbl__item tbl__item--text">
                <TableHeaderItem text={i18next.t("dialog_email")} />
              </div>

              <div className="tbl__item tbl__item--city">
                <TableHeaderItem text={i18next.t("dialog_address")} />
              </div>
              <div className="tbl__item tbl__item--text">
                <TableHeaderItem text={i18next.t("dialog_contact")} />
              </div>

              <div className="tbl__item tbl__item--text">
                <TableHeaderItem text={i18next.t("dialog_phone")} />
              </div>

              <div className="tbl__item tbl__item--city">
                <TableHeaderItem text={i18next.t("dialog_comment")} />
              </div>

              <div style={{ width: 60 }} />
            </div>
          </div>

          <div className="tbl__body">
            {blds &&
              blds.map((item, index) => (
                <BuildingsItem
                  data={item}
                  isUser
                  editBld={(e) => self.editBld(e)}
                  delBld={(e) => self.delBld(e)}
                  onClick={() =>
                    self.setState({ bldId: item.id, slotInfoOpen: true })
                  }
                />
              ))}
          </div>
        </div>

        <BldDialog
          handleClose={self.closeBldDialog}
          id={bldId}
          dAddress={dAddress}
          dName={dName}
          dEmail={dEmail}
          dPhone={dPhone}
          dPerson={dPerson}
          dComment={dComment}
          dNameI18N={dNameI18N}
          dDescI18N={dDescI18N}
          dialogOpen={bldDialog}
          langList={LANG_LIST}
          isMultilang={true}
        />

        {isLoading && (
          <div className="shadow">
            <div className="shadow_circle">
              <CircularProgress />
            </div>
          </div>
        )}
        <BldInfo
          handleClose={() => self.setState({ slotInfoOpen: false })}
          data={rooms}
          id={bldId}
          open={slotInfoOpen}
          text_rooms={i18next.t("rooms_list")}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication, schedule } = state;

  return {
    user,
    app,
    authentication,
    schedule,
  };
}

const connectedTeamPage = connect(mapStateToProps)(BuildingsPage);
export { connectedTeamPage as BuildingsPage };
