import React from "react";
import "../css/style.css";
import Button from "@mui/material/Button";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";

export default function RoomItem(props) {
  const {
    number,
    description,
    equipment,
    time_from,
    time_to,
    price_hour,
    price_day,
    seats,
    index,
    isClosed,
  } = props;

  return (
    <div style={{ display: "flex", flex: 1, marginBottom: 10 }}>
      <div className="form__col">
        <div className="form__group">
          <div style={{ display: "flex", flex: 1 }}>
            <div style={{ marginRight: 5, fontSize: 20 }}>{index + 1}.</div>
            <div
              className="title-small"
              onClick={() => props.clickExpand(index)}
            >
              {!isClosed ? (
                <ExpandLess fontSize="medium" />
              ) : (
                <ExpandMore fontSize="medium" />
              )}
            </div>
            <input
              id="text1"
              type="text"
              className="form__input"
              name="number"
              value={number}
              onChange={(e) =>
                props.handleChange(index, "number", e.target.value)
              }
              placeholder="Название помещения"
              style={{ marginBottom: 10 }}
            />
          </div>
        </div>

        <Collapse in={!isClosed} timeout="auto">
          <div className="form__group">
            <input
              id="text11"
              type="text"
              className="form__input"
              name="description"
              value={description}
              onChange={(e) =>
                props.handleChange(index, "description", e.target.value)
              }
              placeholder="Описание помещения"
              style={{ marginLeft: 15 }}
            />
          </div>
          <div className="form__group">
            <input
              id="text11"
              type="text"
              className="form__input"
              name="seats"
              value={seats}
              onChange={(e) =>
                props.handleChange(index, "seats", e.target.value)
              }
              placeholder="Вместимость"
              style={{ marginLeft: 15 }}
            />
          </div>
          <div className="form__group">
            <div>Оборудование</div>
            <input
              id="text12"
              type="text"
              className="form__input"
              name="equipment"
              value={equipment}
              onChange={(e) =>
                props.handleChange(index, "equipment", e.target.value)
              }
              placeholder="Оборудование"
              style={{ marginTop: 10 }}
            />
          </div>

          <div className="form__group">
            <div style={{ fontSize: 16, fontWeight: 800 }}>
              Время и стоимость аренды
            </div>
            <div style={{ display: "flex", flex: 1, marginTop: 10 }}>
              <div style={{ marginRight: 5, minWidth: 200, paddingTop: 10 }}>
                Время начала
              </div>
              <input
                id="text2"
                type="text"
                className="form__input"
                name="time_from"
                value={time_from}
                onChange={(e) =>
                  props.handleChange(index, "time_from", e.target.value)
                }
                placeholder="Время начала"
                style={{ marginBottom: 10 }}
              />
            </div>
            <div style={{ display: "flex", flex: 1 }}>
              <div style={{ marginRight: 5, minWidth: 200, paddingTop: 10 }}>
                Время окончания
              </div>
              <input
                id="text3"
                type="text"
                className="form__input"
                name="time_to"
                value={time_to}
                onChange={(e) =>
                  props.handleChange(index, "time_to", e.target.value)
                }
                placeholder="Время окончания"
                style={{ marginBottom: 10 }}
              />
            </div>
            <div style={{ display: "flex", flex: 1 }}>
              <div style={{ marginRight: 5, minWidth: 200, paddingTop: 10 }}>
                Цена за час
              </div>
              <input
                id="text4"
                type="text"
                className="form__input"
                name="price_hour"
                value={price_hour}
                onChange={(e) =>
                  props.handleChange(index, "price_hour", e.target.value)
                }
                placeholder="Цена за час"
                style={{ marginBottom: 10 }}
              />
            </div>
            <div style={{ display: "flex", flex: 1 }}>
              <div style={{ marginRight: 5, minWidth: 200, paddingTop: 10 }}>
                Цена за сутки
              </div>
              <input
                id="text5"
                type="text"
                className="form__input"
                name="price_day"
                value={price_day}
                onChange={(e) =>
                  props.handleChange(index, "price_day", e.target.value)
                }
                placeholder="Цена за сутки"
              />
            </div>
          </div>
          <div style={{ display: "flex", flex: 1, marginBottom: 20 }}>
            <Button
              style={{ marginRight: 20 }}
              variant="contained"
              onClick={() => props.saveRoom(index)}
            >
              Сохранить
            </Button>
            <Button onClick={() => props.delRoom(index)}>Удалить</Button>
          </div>
        </Collapse>
      </div>
    </div>
  );
}
