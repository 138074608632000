import * as React from 'react';
import i18next from 'i18next';
import Button from "@material-ui/core/Button";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";

export default function Requisites(props) {
  const [isOpen, setIsOpen] = React.useState(false);
  return (<div style={{ marginLeft: 10, marginTop: 10 }}>
    <div style={{ marginLeft: 10, marginTop: 20 }}>
      <h3 className="layout__title" onClick={() => setIsOpen(!isOpen)}>
        {i18next.t("company_details")}
        {isOpen ? <ExpandLess fontSize="large" /> : <ExpandMore fontSize="large" />}
      </h3>
      <Collapse in={isOpen} timeout="auto">
        {props.req_list.map(function (item, index) {
          return (
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                marginBottom: 10,
                aligItems: "center",
              }}
            >
              <div
                style={{
                  minWidth: 400,
                  marginRight: 10,
                  fontSize: 16,
                  paddingTop: 10,
                }}
              >
                {item}
              </div>
              <input
                placeholder={item}
                value={props.req_data[index] ? props.req_data[index].val : ""}
                onChange={e => props.setReq(index, e.target.value)}
              />
            </div>
          );
        })}
        <Button
          variant="contained"
          onClick={props.onSave}
          style={{ marginTop: 10 }}
        >
          {i18next.t("btn_save")}
        </Button>
      </Collapse>
    </div>
  </div>);
}