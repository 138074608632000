import React from "react";
import "../css/style.css";
import { serverUrl } from "../_constants";
import confLogo from "../img/conf_logo.png";
import { confService } from "../_services";
import FormatIcons from '../_components/FormatIcons';
import onlineLogo from "../img/online.png";
import offlineLogo from "../img/offline.png";


export default function SiteItem(props) {

  const { data } = props;
  
    let img_url = '';
  
  if  (data.documents)
  {
	  let img_data = data.documents[data.documents.length-1];
	  if (img_data)
		  img_url = img_data.path;
  }
  


  return (
    <li className="conf-list__item">
      <div className="card" onClick={props.onClick}>
        <div className="card__top">
            
          <img
            src={img_url? serverUrl + img_url : confLogo}
            alt=""
            className="object-fit is--cover"
          />
          <div>
         {data.type == "offline" && (
            <span className="card__label"><span>
            оффлайн
            </span>
            <img style={{marginLeft:10}} src={offlineLogo} />
            </span>
          )}
          {data.type == "online" && (
            <span className="card__label">
            <span>
            онлайн
            </span>
            <img style={{marginLeft:10}} src={onlineLogo} />
            </span>
          )}
          {data.type == "hybrid" && (
            <span className="card__label">
          <span>
            гибридный
            </span>
            <img style={{marginLeft:10}} src={onlineLogo} />
            <img style={{marginLeft:10}} src={offlineLogo} />
            </span>
          )}
          </div>
        </div>

        <div className="card__body">
          <p style={{ marginBottom: 10, fontSize: 18, fontWeight: 500 }}>
            {data.name}
          </p>

          <p
            style={{
              marginBottom: 10,
              marginTop: 5,
              fontSize: 14,
              fontWeight: 500,
            }}
          >
		  {data.description?data.description:'Нет описания'}
		
          </p>
		  
		   <p
            style={{
              marginBottom: 10,
              marginTop: 5,
              fontSize: 14,
              fontWeight: 500,
            }}
          >
		  {data.tags?data.tags:'Не задан тип'}
		
          </p>
          
          <div className="layout__inf">
          

            {!data.address && (
              <p className="layout__inf-text">Нет адреса</p>
            )}
            {data.address && (
              <p className="layout__inf-text">{data.address}</p>
            )}

            <ul className="layout__inf-blocks">
              <li className="layout__inf-block">
                <div className="layout__inf-media">
                  <svg width="24" height="24">
                    <use href="./img/sprite.svg#user-icon" />
                  </svg>

                  <p className="layout__inf-contains">
                    <strong>
                      {data.tickets_count ? data.tickets_count : "0"}
                    </strong>{" "}
                    <br />
                    участников
                  </p>
                </div>
              </li>

            </ul>
          </div>

        </div>
      </div>
    </li>
  );
}
