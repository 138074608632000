import { authHeader, store, history } from "../_helpers";
import axios from "axios";

import { serverUrl } from "../_constants";
import { reduxLoader } from "./reduxLoader.service";
import { orgConferencesActions } from "../_actions";

export const userService = {
  getAsync,
  getWrapper,
  putWrapper,
  postWrapper,
  patchWrapper,
  patchWrapperBody,
  delWrapper,
  awaitPost,
  uploadFile,
  setStorage,
  getStorage,
};

const UNAUTH = "Error: Request failed with status code 401";

function getAsync(urls, cbF) {
  const appToken = getStorage("token");
  const lang = getStorage("navic_lang") ?? "ru";
  axios.defaults.headers.get.Authorization = `Bearer ${appToken}`;
  axios.defaults.headers.get["N-Fixed-Locale"] = lang;

  axios.defaults.headers.get["Content-Type"] = "application/json";
  axios.defaults.withCredentials = true;

  let aUrls = [];

  for (let i = 0; i < urls.length; i++)
    aUrls.push(axios.get(serverUrl + urls[i]));

  Promise.all(aUrls).then((values) => {
    cbF(values);
  });
}

function uploadFile(url, data, onSuccess, onError) {
  let appToken = getStorage("token");
  axios.defaults.headers.post.Authorization = `Bearer ${appToken}`;
  const lang = getStorage("navic_lang") ?? "ru";
  axios.defaults.headers.post["N-Fixed-Locale"] = lang;

  axios
    .post(serverUrl + url, data, {})
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
}

function getWrapper(url, onSuccess, onError,noRedirect = false) {
  let appToken = getStorage("token");
  const lang = getStorage("navic_lang") ?? "ru";
  axios.defaults.headers.get.Authorization = `Bearer ${appToken}`;
  axios.defaults.headers.get["N-Fixed-Locale"] = lang;
  // alert(appToken);

  axios.defaults.headers.get["Content-Type"] = "application/json";
  axios.defaults.withCredentials = true;

  axios
    .get(serverUrl + url)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {


      if (error == UNAUTH && !noRedirect) history.push("/login");
      else onError(error);
    });
}

function putWrapper(url, data, onSuccess, onError) {
  let appToken = getStorage("token");
  axios.defaults.headers.put["X-Api-Key"] = appToken;

  axios.defaults.headers.put["Content-Type"] = "application/json";
  axios.defaults.withCredentials = true;

  axios
    .put(serverUrl + url, data)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      if (error == UNAUTH) history.push("/login");
      else onError(error);
    });
}

function patchWrapper(url, onSuccess, onError) {
  let appToken = getStorage("token");
  axios.defaults.headers.patch.Authorization = `Bearer ${appToken}`;
  const lang = getStorage("navic_lang") ?? "ru";
  axios.defaults.headers.patch["N-Fixed-Locale"] = lang;

  axios.defaults.headers.patch["Content-Type"] = "application/json";
  axios.defaults.withCredentials = true;

  axios
    .patch(serverUrl + url)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      if (error == UNAUTH) history.push("/login");
      else onError(error);
    });
}

function patchDispatchers(url) {
  switch (true) {
    case /api\/conference\/\d+/.test(url):
      reduxLoader.orgConferences(); //ToDo: update state without ajax-request
      break;
    case /api\/team\/\d+/.test(url):
      reduxLoader.team(); //ToDo: update state without ajax-request
      break;
    default:
  }
}

function patchWrapperBody(url, data, onSuccess, onError) {
  let appToken = getStorage("token");
  axios.defaults.headers.patch.Authorization = `Bearer ${appToken}`;
  const lang = getStorage("navic_lang") ?? "ru";
  axios.defaults.headers.patch["N-Fixed-Locale"] = lang;

  axios.defaults.headers.patch["Content-Type"] = "application/json";
  axios.defaults.withCredentials = true;

  axios
    .patch(serverUrl + url, data)
    .then((response) => {
      onSuccess(response);
      patchDispatchers(url);
    })
    .catch((error) => {
      if (error == UNAUTH) history.push("/login");
      else onError(error);
    });
}

function postDispatchers(url) {
  switch (url) {
    case "api/conference":
      reduxLoader.orgConferences(); //ToDo: update state without ajax-request
      break;
    case "api/team":
      reduxLoader.team(); //ToDo: update state without ajax-request
      break;
    default:
  }
}

function postWrapper(url, data, onSuccess, onError) {
  let appToken = getStorage("token");
  axios.defaults.headers.post.Authorization = `Bearer ${appToken}`;
  const lang = getStorage("navic_lang") ?? "ru";
  axios.defaults.headers.post["N-Fixed-Locale"] = lang;

  axios.defaults.headers.post["Content-Type"] = "application/json";
  axios.defaults.withCredentials = true;
  console.log(url);

  axios
    .post(serverUrl + url, data)
    .then((response) => {
      console.log(response);
      onSuccess(response);
      postDispatchers(url);
    })
    .catch((error) => {
      if (error == UNAUTH) history.push("/login");
      else onError(error);
    });
}

function awaitPost(url) {
  let appToken = getStorage("token");
  axios.defaults.headers.post.Authorization = `Bearer ${appToken}`;

  axios.defaults.headers.post["Content-Type"] = "application/json";
  axios.defaults.withCredentials = true;

  let data = axios.post(serverUrl + url);
  console.log(data);

  return data;
}

function delDispatchers(url) {
  switch (true) {
    case /api\/conference\/\d+/.test(url):
      store.dispatch(orgConferencesActions.del(url.match(/\d+/)[0]));
      break;
    case /api\/team\/\d+/.test(url):
      reduxLoader.team(); //ToDo: update state without ajax-request
      break;
    default:
  }
}

function delWrapper(url, onSuccess, onError) {
  let appToken = getStorage("token");
  axios.defaults.headers.delete.Authorization = `Bearer ${appToken}`;

  axios.defaults.withCredentials = true;

  axios
    .delete(serverUrl + url)
    .then((response) => {
      onSuccess(response);
      delDispatchers(url);
    })
    .catch((error) => {
      if (error == UNAUTH) history.push("/login");
      else onError(error);
    });
}

function getCookie(name) {
  let matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
        "=([^;]*)"
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function getStorage(name) {
  return localStorage.getItem(name);
}

function setStorage(name, val) {
  localStorage.setItem(name, val);
}
