import HelpIcon from "@material-ui/icons/Help";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import React from "react";

function TextFieldHint(props) {
  const {
    id,
    type,
    value,
    error,
    name,
    label,
    placeholder,
    className,
    autoFocus,
    inputProps,
    multiline,
    hint,
    onChange,
  } = props;

  function SimpleDialog(props) {
    const { onClose, hint, open } = props;

    const handleClose = () => {
      onClose();
    };

    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>{hint}</DialogTitle>
      </Dialog>
    );
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TextField
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              {hint && <HelpIcon onClick={handleClickOpen} />}
            </InputAdornment>
          ),
        }}
        autoComplete="off"
        autoFocus={autoFocus}
        multiline={multiline}
        margin="dense"
        placeholder={label}
        id="desc"
        label={label}
        fullWidth
        value={value}
        variant="standard"
        onChange={onChange}
      />
      <SimpleDialog
        open={open}
        onClose={handleClose}
        hint={hint ?? "hint data"}
      />
    </Box>
  );
}

export default TextFieldHint;
