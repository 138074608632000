import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button } from '@mui/material';
import i18next from 'i18next';

export default function ConferenceAnketaList(props) {
  const addToAnketa = value => {
    anketa.push(value);
    props.parentProps.stateSetter(anketaKey, anketa);
  };

  const removeFromAnketa = value => {
    anketa = anketa.filter(item => item != value);
    props.parentProps.stateSetter(anketaKey, anketa);
  };

  const getItem = item => {
    return (allFields[item] && <Box>{allFields[item].title} <Button key={item} onClick={() => removeFromAnketa(item)}>X</Button></Box>);
  };

  let {anketaKey, anketa, additionalAnketa} = props;
  const allFields = props.parentProps.allAnketaFields;
  const fieldsToAdd = Object.keys(allFields).filter(
    field => {
      for (let i = 0; i < anketa.length; i++) {
        if (anketa[i] == field) {
          return false;
        }
      }
      for (let i = 0; i < additionalAnketa.length; i++) {
        if (additionalAnketa[i] == field) {
          return false;
        }
      }
      return true;
    }
  );
  const [current, setCurrent] = React.useState(fieldsToAdd.length > 0 ? fieldsToAdd[0] : null);
  let currentInList = false;
  for (let i = 0; i < fieldsToAdd.length; i++) {
    if (fieldsToAdd[i] == current) {
      currentInList = true;
      break;
    }
  }
  if (fieldsToAdd.length > 0 && !currentInList) {
    setCurrent(fieldsToAdd[0]);
  }
  const listItems = fieldsToAdd.map(item => <MenuItem value={item}>{allFields[item].title}</MenuItem>);
  return (<Box>
    {fieldsToAdd.length ? <FormControl>
      <Select onChange={event => setCurrent(event.target.value)} value={current}>
        {listItems}
      </Select>
      <Button onClick={() => addToAnketa(current)}>{i18next.t("ADD")}</Button>
    </FormControl> : ""}
    {(anketa && anketa.length) ? anketa.map(item => getItem(item)) : ""}
  </Box>);
}
