import React from "react";
import SendIcon from "@material-ui/icons/Send";

export default class ItemButtonCustom extends React.Component {
  render() {
    const {id, action} = this.props;
    return (
      <div title={action.label} style={{ width: 20, marginLeft: 10 }}>
        <SendIcon
          width="15"
          height="15"
          onClick={(e) => action.action(id)}
        />
      </div>
    );
  }
}
