import React from "react";
import "../css/style.css";
import { history } from "../_helpers";
import { connect } from "react-redux";
import { store } from "../_helpers";
import SiteItem from "./SiteItem";
import { userService, confService } from "../_services";

import { userActions, appActions } from "../_actions";

class SiteListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      start_date: null,
      end_date: null,
      sites: [],
    };
    store.subscribe(this.storeChange);
  }

  componentDidMount() {
    //this.props.dispatch(userActions.site_list());

    this.getSiteList();
  }

  getSiteList = () => {
    let self = this;

    confService.siteList(
      function (data) {
        console.log(data);
        self.setState({ sites: [...data.data] });
      },
      function (err) {
        console.log(err);
      }
    );
  };

  addNewSite = () => {
    history.push("/new_site");
  };

  storeChange = (e) => {};

  onItemClick = (e) => {};

  onItemEdit = (e) => {
    this.props.dispatch(userActions.set_site(e));
    this.props.dispatch(appActions.setpage("site_param", e.id));
  };

  onItemDelete = (id) => {
    const self = this;
    const res_dialog = window.confirm("Удалить площадку?");
    if (res_dialog == true) {
      userService.delWrapper(
        "api/building/" + id,
        (data) => {
          self.props.dispatch(userActions.conf_list());
          //confService.confListParticipants(false);
          self.getSiteList();
        },
        (error) => {
          self.props.dispatch(userActions.set_error(error));
        }
      );
    }
  };

  changeDate = (index, event) => {
    if (index == 0) this.setState({ start_date: event.target.value });
    else this.setState({ end_date: event.target.value });
  };

  render() {
    const self = this;
    const { start_date, end_date, sites } = this.state;
    const { classes, user } = this.props;

    console.log(start_date, user.conflist);

    return (
      <div className="layout">
        <div className="layout__contains">
          <h1 className="layout__title">Площадки</h1>

          <div style={{ width: 300, display: "flex", flexDirection: "row" }}>
            <div className="date-wrap form__group-row">
              <label htmlFor="start">даты с</label>
              <input
                type="date"
                className="form__input"
                id="start"
                value={start_date}
                name="trip-start"
                onChange={(e) => this.changeDate(0, e)}
              />
            </div>

            <div className="date-wrap form__group-row">
              <label htmlFor="start"> до</label>
              <input
                type="date"
                className="form__input"
                id="start"
                value={end_date}
                name="trip-start"
                onChange={(e) => this.changeDate(1, e)}
              />
            </div>
          </div>

          <div className="layout__btn">
            <button className="btn" onClick={self.addNewSite}>
              Создать площадку
            </button>
          </div>
        </div>

        <ul className="conf-list">
          {sites.length > 0 &&
            sites.map((item, index) => (
              <SiteItem
                data={item}
                key={index}
                onClick={() => self.onItemClick(item.id)}
                onEdit={() => self.onItemEdit(item)}
                onSchedule={() => self.onItemSchedule(item)}
                onDelete={() => self.onItemDelete(item.id)}
              />
            ))}
        </ul>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;

  return {
    user,
    app,
    authentication,
  };
}

const connectedSiteListPage = connect(mapStateToProps)(SiteListPage);
export { connectedSiteListPage as SiteListPage };
