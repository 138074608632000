import * as React from "react";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

import "../css/style.css";
import i18next from "i18next";

const no_text = i18next.t("no_data");

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function MemberInfo(props) {
  const { handleClose, data, open } = props;
  const { t } = useTranslation();

  React.useEffect(() => {});

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        {data && (
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              {t("dialog_name")}: {data.name ? data.name : no_text}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              {t("dialog_email")}: {data.email ? data.email : no_text}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              {t("dialog_phone")}: {data.phone ? data.phone : no_text}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              {t("dialog_city")}: {data.city ? data.city : no_text}
            </Typography>
          </Box>
        )}
      </Fade>
    </Modal>
  );
}
