import * as React from 'react';
import i18next from 'i18next';
import Button from "@material-ui/core/Button";

export default function RefillBalance(props) {
  return (<div style={{ marginLeft: 10, marginTop: 10 }}>
    <h3 className="layout__title">{i18next.t("balance")}</h3>
    <div style={{ display: "flex", flexDirection: "row", fontSize: 18 }}>
      <div style={{ marginRight: 20, marginTop: 10 }}>
      {i18next.t("balance")}: {props.balance}
      </div>
      <Button
        variant="contained"
        onClick={props.moneyAdder}
        style={{ marginRight: 10 }}
      >
        {i18next.t("refill")}
      </Button>
      <input
        type="number"
        title={i18next.t("enter_value")}
        style={{ maxWidth: 150 }}
        value={props.moneyToAdd}
        onChange={e=>props.stateSetter("add_money", e.target.value)}
      />
    </div>
  </div>);
}