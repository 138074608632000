import React from "react";
import "../css/style.css";
import i18next from "i18next";
import { connect } from "react-redux";
import { history, store } from "../_helpers";
import { userService } from "../_services";

import { CircularProgress } from "@material-ui/core";
import { userActions } from "../_actions";

class VoteListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      votelist: [],
      lang: "",
    };
    store.subscribe(this.storeChange);
  }

  componentDidMount() {
    this.setState({ lang: this.props.app.lang });
    this.getVotes();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.app.lang !== this.state.lang) {
      this.setState({ lang: nextProps.app.lang });
      this.getVotes();
    }
  }

  selectVote = (item) => {
    localStorage.setItem("navic_votelink", item.link);
    localStorage.setItem("navic_voteauth", item.need_auth);
    localStorage.setItem("navic_voteanon", item.is_anonymous);
    history.push("/editvote/" + item.id);
  };

  openVote = (item) => {
    window.open("/votes/" + item.id + "?key=" + item.link);
  };

  openVoteRes = (item) => {
    window.open("/voteres/" + item.id + "?key=" + item.result_link);
  };

  getVotes = () => {
    let self = this;

    userService.getWrapper(
      "api/vote",
      (data) => {
        console.log(data);
        if (data.data) {
          self.setState({ votelist: [...data.data], isLoading: false });
        }
      },
      (error) => {
        if (error != "") {
          self.setState({ error, isLoading: false });
          self.props.dispatch(userActions.set_error(error));
        }
      }
    );
  };

  addVote = () => {
    localStorage.setItem("navic_votelink", "");
    localStorage.setItem("navic_voteauth", "0");
    localStorage.setItem("navic_voteanon", "0");
    history.push("/newvote");
  };

  storeChange = (e) => {};

  render() {
    const self = this;
    const { isLoading, votelist } = this.state;

    return (
      <div className="layout">
        <div className="layout__contains">
          <h1 className="layout__title">{i18next.t("votes")}</h1>

          <div className="layout__btn">
            <button className="btn" onClick={self.addVote}>
              {i18next.t("create_vote")}
            </button>
          </div>
        </div>

        <table style={{ width: "100%", marginTop: "10px" }}>
          <thead>
            <tr>
              <th scope="col" style={{ padding: 10, border: "1px solid #ddd" }}>
                {i18next.t("title")}
              </th>
              <th scope="col" style={{ padding: 10, border: "1px solid #ddd" }}>
                {" "}
                {i18next.t("is_anonymos")}
              </th>
              <th scope="col" style={{ padding: 10, border: "1px solid #ddd" }}>
                {i18next.t("need_auth")}
              </th>
              <th scope="col" style={{ padding: 10, border: "1px solid #ddd" }}>
                {i18next.t("url")}
              </th>
              <th scope="col" style={{ padding: 10, border: "1px solid #ddd" }}>
                {i18next.t("results")}
              </th>
            </tr>
          </thead>
          <tbody>
            {votelist &&
              votelist.map((item, index) => (
                <tr>
                  <td style={{ padding: 10, border: "1px solid #ddd" }}>
                    <div
                      onClick={() => self.selectVote(item)}
                      style={{
                        fontSize: 16,
                        color: "blue",
                        textDecoration: "underline",
                        fontWeight: 700,
                        cursor: "pointer",
                      }}
                    >
                      {item.header ?? i18next.t("no_title")}
                    </div>
                  </td>
                  <td style={{ padding: 10, border: "1px solid #ddd" }}>
                    {item.is_anonymous
                      ? i18next.t("btn_yes")
                      : i18next.t("btn_no")}
                  </td>
                  <td style={{ padding: 10, border: "1px solid #ddd" }}>
                    {item.need_auth
                      ? i18next.t("btn_yes")
                      : i18next.t("btn_no")}
                  </td>
                  <td style={{ padding: 10, border: "1px solid #ddd" }}>
                    <div
                      onClick={() => self.openVote(item)}
                      style={{
                        marginLeft: 10,
                        marginTop: 5,
                        fontSize: 16,
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      {i18next.t("url")}
                    </div>
                  </td>
                  <td style={{ padding: 10, border: "1px solid #ddd" }}>
                    <div
                      onClick={() => self.openVoteRes(item)}
                      style={{
                        marginLeft: 10,
                        marginTop: 5,
                        fontSize: 16,
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      {i18next.t("results")}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        {isLoading && (
          <div className="shadow">
            <div className="shadow_circle">
              <CircularProgress />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;

  return {
    user,
    app,
    authentication,
  };
}

const connectedTeamPage = connect(mapStateToProps)(VoteListPage);
export { connectedTeamPage as VoteListPage };
