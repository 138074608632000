import React from "react";
import i18next from "i18next";
import EditIcon from "@material-ui/icons/Edit";

export default class ItemButtonEdit extends React.Component {
  render() {
    return (
      <div title={i18next.t("btn_edit")} style={{ width: 20, marginLeft: 10 }}>
        <EditIcon
          width="15"
          height="15"
          onClick={(e) => this.props.action(this.props.id)}
        />
      </div>
    );
  }
}
