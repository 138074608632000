import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import Checkbox from "@mui/material/Checkbox";
import TextFieldI18N from "./TextFieldI18N";
import TextFieldHint from "./TextFieldHint";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export default function AddDialog(props) {
  const [value, setValue] = React.useState("");

  const [description, setDesc] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [city, setCity] = React.useState("");
  const [comm, setComm] = React.useState("");
  const [seats, setSeats] = React.useState("");
  const [name_i18n, setNameI18N] = React.useState({});
  const [description_i18n, setDescI18N] = React.useState({});
  const [city_i18n, setCityI18N] = React.useState({});
  const [comment_i18n, setCommI18N] = React.useState({});
  const [count, setCount] = React.useState(0);
  const [isServiceSlot, setServiceSlot] = React.useState(false);
  const [tags, setTags] = React.useState("");

  const hintEnabled = true;

  const {
    handleClose,
    label,
    nameLabel,
    title,
    dialogOpen,
    onFileUpload,
    dialogType,
    fileStatus,
    langList,
    isMultilang,
  } = props;

  const { t } = useTranslation();

  function addTheme() {
    const tmpObj = { name: value, name_i18n: name_i18n };

    tmpObj.description = description;
    tmpObj.description_i18n = description_i18n;

    if (dialogType == "theme" && isServiceSlot) tmpObj.isServiceSlot = true;
    if (dialogType == "theme" && !isServiceSlot) tmpObj.tags = tags;

    if (dialogType == "speaker") {
      tmpObj.description = description;
      tmpObj.phone = phone;
      tmpObj.city = city;
      tmpObj.comment = comm;
      tmpObj.email = email;

      tmpObj.city_i18n = city_i18n;
      tmpObj.comment_i18n = comment_i18n;
      tmpObj.name_i18n = name_i18n;
      tmpObj.description_i18n = description_i18n;
      tmpObj.city_i18n = city_i18n;
    }

    if (dialogType == "room") {
      tmpObj.seats = seats;
    }

    handleClose(tmpObj);
    setCount(0);
    setValue("");
    setPhone("");
    setDesc("");
    setEmail("");
    setComm("");
    setCity("");
    setNameI18N({});
    setDescI18N({});
    setCityI18N({});
    setCommI18N({});
    setTags("");
    setServiceSlot(false);
  }

  function cancelTheme() {
    handleClose(null);
    setCount(0);
    setValue("");
    setPhone("");
    setDesc("");
    setEmail("");
    setComm("");
    setCity("");
    setSeats("");
    setTags("");
    setNameI18N({});
    setDescI18N({});
    setCityI18N({});
    setCommI18N({});
    setServiceSlot(false);
  }

  function onDrop(acceptedFiles, rejectedFiles) {
    console.log(acceptedFiles);

    if (acceptedFiles.length > 0) {
      onFileUpload(acceptedFiles[0]);
    }
  }

  return (
    <Dialog open={dialogOpen}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {dialogType === "theme" && (
          <div>
            <Checkbox
              checked={isServiceSlot}
              onClick={() => setServiceSlot(!isServiceSlot)}
            />
            <span style={{ fontSize: 18 }}>{t("service_slot")}</span>
          </div>
        )}

        <TextFieldI18N
          autoFocus
          margin="dense"
          id="name"
          label={nameLabel}
          fullWidth
          variant="standard"
          value={isMultilang ? name_i18n : value}
          hint={hintEnabled?i18next.t("hint_dialog_name"):''}
          onChange={(e, tabName) => {
            let tmpI18N = name_i18n;
            tmpI18N[tabName] = e.target.value;

            if (isMultilang) {
              setNameI18N(tmpI18N);
              setValue(tmpI18N["ru"]);
            } else {
              setValue(e.target.value);
            }
            setCount(count + 1);
          }}
          langList={langList}
          isMultilang={isMultilang}
        />
        {(dialogType == "speaker" ||
          dialogType == "room" ||
          (dialogType == "theme" && !isServiceSlot)) && (
          <TextFieldI18N
            margin="dense"
            id="desc"
            label={t("dialog_desc")}
            fullWidth
            variant="standard"
            hint={hintEnabled?i18next.t("hint_dialog_desc"):''}
            value={isMultilang ? description_i18n : description}
            onChange={(e, tabName) => {
              let tmpI18N = description_i18n;
              tmpI18N[tabName] = e.target.value;

              if (isMultilang) {
                setDescI18N(tmpI18N);
                setDesc(tmpI18N["ru"]);
              } else {
                setDesc(e.target.value);
              }
              setCount(count + 1);
            }}
            langList={langList}
            isMultilang={isMultilang}
          />
        )}

        {dialogType == "speaker" && (
          <TextFieldHint
            margin="dense"
            id="email"
            label={t("dialog_email")}
            hint={hintEnabled?i18next.t("hint_dialog_email"):''}
            fullWidth
            variant="standard"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setCount(count + 1);
            }}
          />
        )}
        {dialogType == "speaker" && (
          <TextFieldHint
            margin="dense"
            id="phone"
            label={t("dialog_phone")}
            hint={hintEnabled?i18next.t("hint_dialog_phone"):''}
            fullWidth
            variant="standard"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
              setCount(count + 1);
            }}
          />
          
        )}
        {dialogType == "speaker" && (
          <TextFieldI18N
            margin="dense"
            id="phone"
            label={t("dialog_city")}
            hint={hintEnabled?i18next.t("hint_dialog_city"):""}
            fullWidth
            variant="standard"
            value={isMultilang ? city_i18n : city}
            onChange={(e, tabName) => {
              let tmpI18N = city_i18n;
              tmpI18N[tabName] = e.target.value;

              if (isMultilang) {
                setCityI18N(tmpI18N);
                setCity(tmpI18N["ru"]);
              } else {
                setCity(e.target.value);
              }
              setCount(count + 1);
            }}
            langList={langList}
            isMultilang={isMultilang}
          />
        )}
        {dialogType == "speaker" && (
          <TextFieldI18N
            margin="dense"
            id="phone"
            label={t("dialog_comment")}
            hint={hintEnabled?i18next.t("hint_dialog_comment"):''}
            fullWidth
            variant="standard"
            value={isMultilang?comment_i18n:comm}
            onChange={(e, tabName) => {
              let tmpI18N = comment_i18n;
              tmpI18N[tabName] = e.target.value;

              if (isMultilang)
              {
              setCommI18N(tmpI18N);
              setComm(tmpI18N["ru"]);
              }
              else
              {
                setComm(e.target.value);
              }
              setCount(count + 1);
            }}
            langList={langList}
            isMultilang={isMultilang}
          />
        )}
        {dialogType == "room" && (
          <TextFieldHint
            margin="dense"
            id="room"
            label={t("dialog_seats")}
            hint={hintEnabled?i18next.t("hint_dialog_seats"):""}
            fullWidth
            variant="standard"
            value={seats}
            onChange={(e) => {
              setSeats(e.target.value);
              setCount(count + 1);
            }}
          />
        )}
        {dialogType == "theme" &&
          !isServiceSlot && (
            <TextFieldHint
              margin="dense"
              id="tags"
              label={t("dialog_tags")}
              hint={hintEnabled ? i18next.t("hint_dialog_tags") : ""}
              fullWidth
              variant="standard"
              value={tags}
              onChange={(e) => {
                setTags(e.target.value);
                setCount(count + 1);
              }}
            />
          )}
        {dialogType == "theme" && !isServiceSlot && (
          <div style={{ marginTop: 10 }}>
            <div style={{ paddingBottom: 5 }}>{t("presentation_file")}</div>
            <Dropzone onDrop={onDrop} class="visually--hidden">
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div
                  style={{
                    textAlign: "center",
                    verticalAlign: "center",
                    width: 400,
                    height: 50,
                    background: "#36B2F1",
                    borderRadius: 5,
                  }}
                  {...getRootProps()}
                  className={classNames("dropzone", {
                    "dropzone--isActive": isDragActive,
                  })}
                >
                  <input {...getInputProps()} />

                  {fileStatus == 0 &&
                    (isDragActive ? (
                      <p style={{ fontSize: 12 }}>
                        {" "}
                        {t("dropzone_file_upload")}
                      </p>
                    ) : (
                      <p style={{ fontSize: 12, padding: 10, borderRadius: 5 }}>
                        {t("dropzone_title_hint")}
                      </p>
                    ))}
                  {fileStatus == 1 && (
                    <p style={{ fontSize: 12, padding: 10 }}>
                      {t("dropzone_file_uploaded")}
                    </p>
                  )}
                </div>
              )}
            </Dropzone>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={addTheme}>{t("btn_save")}</Button>
        <Button onClick={cancelTheme}>{t("btn_cancel")}</Button>
      </DialogActions>
    </Dialog>
  );
}
