import React from "react";
import "../css/style.css";
import { Button } from "@material-ui/core";
import i18next from "i18next";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import personLogo from "../img/person.png";
import { serverUrl } from "../_constants";

export default function PartItem(props) {
  const anketaField = (field, value) => {
    return (
      <div className="tbl__body-row">
        <div className="tbl__items">
          <div className="tbl__item tbl__item--city">
            <span className="profile__name">{field}</span>
          </div>
          <div className="tbl__item tbl__item--text">
            <span>{value}</span>
          </div>
        </div>
      </div>
    );
  };

  const [anketaOpen, setAnketaOpen] = React.useState(false);
  const name = props.data ? props.data.user_name : "";
  const avatar = props.data.avatar ? serverUrl + props.data.avatar : personLogo;
  return (
    <div className="tbl__body-row">
      <div className="tbl__items">
        <img src={avatar} alt="" className="profile__img" />
        {props.isOrg && (
          <div className="tbl__item tbl__item--city">
            <Button onClick={props.downloadBadge}>
              {i18next.t("download_bagde")}
            </Button>
          </div>
        )}
        <div className="tbl__item tbl__item--city">
          <span className="profile__name">{name}</span>
        </div>
        <div className="tbl__item tbl__item--city">
          <span>{props.data ? props.data.conf_name : ""}</span>
        </div>
        <div className="tbl__item tbl__item--city">
          <Button onClick={() => setAnketaOpen(true)}>
            {i18next.t("details")}
          </Button>
        </div>
        {!props.forStudent && (
          <div className="tbl__item tbl__item--text">
            <span>{props.data ? props.data.ticket_name : ""}</span>
          </div>
        )}
        {!props.forStudent && (
          <div className="tbl__item tbl__item--text">
            <span>
              {props.data ? props.data.updated_at.replace(".000000Z", "") : ""}
            </span>
          </div>
        )}
      </div>
      <Dialog open={anketaOpen}>
        <DialogTitle>{name}</DialogTitle>
        <DialogContent>
          {avatar.length > 0 && <img src={avatar} alt="" />}
          {props.data.user_email.length > 0 &&
            anketaField(i18next.t("dialog_email"), props.data.user_email)}
          {Object.keys(props.data.anketa).map((field) => {
            const value = props.data.anketa[field];
            if (typeof value === "string" || value instanceof String) {
              return anketaField(i18next.t("ank_" + field), value);
            } else if (typeof value === "object" && value !== null) {
              return anketaField(value.title, value.value);
            }
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAnketaOpen(false)}>
            {i18next.t("btn_cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
