import { combineReducers } from "redux";

import { authentication } from "./authentication.reducer";
import { user } from "./user.reducer";
import { app } from "./app.reducer";
import { schedule } from "./schedule.reducer";
import { team } from "./team.reducer";
import { orgConferences } from "./orgConferences.reducer";
import { studentConferences } from "./studentConferences.reducer";

const rootReducer = combineReducers({
  app,
  authentication,
  user,
  schedule,
  team,
  orgConferences,
  studentConferences,
});

export default rootReducer;
