import React from "react";
import "../css/style.css";

import { connect } from "react-redux";
import Button from "@mui/material/Button";
import { CircularProgress } from "@material-ui/core";
import { userActions, appActions } from "../_actions";
import PartItem from "./PartItem";
import { userService } from "../_services";
import { serverUrl } from "../_constants";
import i18next from "i18next";

class BookingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      tickets: [],
      nameF: "",
      dateF: "",
      qrData: null,
      openQR: false,
    };
  }

  componentDidMount() {
    //this.props.dispatch(userActions.conf_student_list(0));
    this.LoadBookings();
  }

  LoadBookings() {
    let self = this;
    userService.getWrapper(
      "api/booking/",
      (data) => {
        const ticket_data = data.data.map(function (item) {
          let bld_name = "";
          let bld_addr = "";

          if (item.room.building && item.room.building.name)
            bld_name = item.room.building.name;

          if (item.room.building && item.room.building.address)
            bld_addr = item.room.building.address;

          return {
            id: item.id,
            name: bld_name,
            room: item.room.number,
            address: bld_addr,
            start_date: item.start_time.replace(".000000Z", ""),
            end_date: item.end_time.replace(".000000Z", ""),
          };
        });

        console.log(ticket_data);

        self.setState({ tickets: ticket_data });
      },
      (error) => {
        self.props.dispatch(userActions.set_error(error));
      }
    );
  }

  ticketClick = (id) => {
    const { classes, dispatch } = this.props;

    userService.getWrapper(
      "api/ticket/" + id,
      (data) => {
        console.log(data);
        if (data.data.img) {
          this.setState({ qrData: data.data.img, openQR: true });
        }
      },
      (error) => {
        dispatch(userActions.set_error(error));
      }
    );
  };
  
  clickCal = () => {
	  
	  	  const { classes, dispatch } = this.props;
	  
	    userService.getWrapper(
      "api/booking/export/ics",
      (data) => {
        
		if (data.data)
				window.open(serverUrl+data.data);
		
      },
      (error) => {
        dispatch(userActions.set_error(error));
      }
    );
  }

  render() {
    const self = this;
    const { isLoading, tickets, nameF, dateF } = this.state;

    return (
      <div className="layout">
        <div className="layout__contains">
          <h1 className="layout__title">{i18next.t("bookings")}</h1>
		  <Button variant="contained" onClick={this.clickCal}>{i18next.t("download_calendar")}</Button>
        </div>

        <div className="tbl">
          <div className="tbl__top">
            <div className="tbl__items">
              <div className="tbl__item tbl__item--city">
                <input
                  type="text"
                  placeholder={i18next.t("search_by_site")}
                  value={nameF}
                  onChange={(e) => this.setState({ nameF: e.target.value })}
                />
              </div>
              <div className="tbl__item tbl__item--text">
                <input type="text" placeholder="Поиск по адресу" />
              </div>

              <div className="tbl__item tbl__item--text">
                <input type="text" placeholder="Поиск по помещению" />
              </div>
              <div className="tbl__item tbl__item--text">
                <input
                  type="text"
                  placeholder="Поиск по дате"
                  value={dateF}
                  onChange={(e) => this.setState({ dateF: e.target.value })}
                />
              </div>
            </div>
          </div>

          <div className="tbl__body">
            {tickets
              .filter((e) => e.name.indexOf(nameF) >= 0)
              .filter(
                (e) =>
                  e.start_date.indexOf(dateF) >= 0 ||
                  e.end_date.indexOf(dateF) >= 0
              )
              .map((item, index) => (
                <PartItem data={item} onItemClick={self.ticketClick} />
              ))}
          </div>
        </div>

        {isLoading && (
          <div className="shadow">
            <div className="shadow_circle">
              <CircularProgress />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;

  return {
    user,
    app,
    authentication,
  };
}

const connectedBookingPage = connect(mapStateToProps)(BookingPage);
export { connectedBookingPage as BookingPage };
