import { userService } from "./user.service";
import { orgConferencesActions, teamActions, userActions, studentConferencesActions } from "../_actions";
import { store } from "../_helpers";

const load = (url, action) => {
  store.dispatch(userActions.loading(true));

  let userdata = localStorage.getItem("navic_user");

  if (!userdata) return;

  userService.getWrapper(
    url,
    (data) => {
      store.dispatch(userActions.loading(false));
      if (data.data) {
        store.dispatch(action(data.data));
      }
    },
    (error) => {
      store.dispatch(userActions.loading(false));
      if (error !== "") {
        console.log(error);
      }
    }
  );
};

export const reduxLoader = {
  orgConferences: () => load("api/conference", orgConferencesActions.load),
  studentConferences: () => load("api/conference-all?hasTicket=1", studentConferencesActions.load),
  team: () => load("api/team?withOrg=1", teamActions.load),
};
