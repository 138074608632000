import React from "react";
import "../css/style.css";
import { serverUrl } from "../_constants";
import confLogo from "../img/conf_logo.png";
import { confService } from "../_services";

export default function SiteItem(props) {
  const { data } = props;

  let img_url = "";

  if (data.documents) {
    let img_data = data.documents[data.documents.length - 1];
    if (img_data) img_url = img_data.path;
  }

  return (
    <li className="conf-list__item">
      <div className="card" onClick={props.onClick}>
        <div className="card__top" onClick={props.onEdit}>
          <img
            src={img_url ? serverUrl + img_url : confLogo}
            alt=""
            className="object-fit is--cover"
          />
          <div></div>
        </div>

        <div className="card__body">
          <p style={{ marginBottom: 10, fontSize: 18, fontWeight: 500 }}>
            {data.name}
          </p>

          <p
            style={{
              marginBottom: 10,
              marginTop: 5,
              fontSize: 14,
              fontWeight: 500,
            }}
          >
            {data.is_published == 1 ? "Опубликована" : "Не опубликована"}
          </p>
          <div className="layout__inf">
            <ul className="layout__inf-list">
              <li className="layout__inf-item">
                <a onClick={props.onEdit} className="card__link">
                  <span>Редактировать</span>
                </a>
              </li>
              <li className="layout__inf-item">
                <a onClick={props.onDelete} className="card__link">
                  <span>Удалить</span>
                </a>
              </li>
            </ul>
          </div>

          <div className="layout__inf">
            <p className="layout__inf-text">
              {confService.getConfDateTimeStr(data.start_time, data.end_time)}
            </p>

            {!data.address && <p className="layout__inf-text">Нет адреса</p>}
            {data.address && <p className="layout__inf-text">{data.address}</p>}

            <ul className="layout__inf-blocks">
              <li className="layout__inf-block">
                <div className="layout__inf-media">
                  <svg width="24" height="24">
                    <use href="./img/sprite.svg#user-icon" />
                  </svg>

                  <p className="layout__inf-contains">
                    <strong>
                      {data.tickets_count ? data.tickets_count : "0"}
                    </strong>{" "}
                    <br />
                    участников
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </li>
  );
}
