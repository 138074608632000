import React from "react";
import "../css/style.css";

export default function PartItem(props) {
  return (
    <div className="tbl__body-row">
      <div className="tbl__items">
        <div className="tbl__item tbl__item--city" >
          <span style={{textDecoration:'underline'}}>{props.data ? props.data.name : ""}</span>
        </div>
		<div className="tbl__item tbl__item--text">
          <span>{props.data ? props.data.address : ""}</span>
        </div>
		<div className="tbl__item tbl__item--text">
          <span>{props.data ? props.data.room : ""}</span>
        </div>
        <div className="tbl__item tbl__item--text">
          <span>{props.data ? props.data.start_date+" - "+props.data.end_date : ""}</span>
        </div>
		
      </div>
    </div>
  );
}
