import React, { useEffect } from "react";
import { connect } from "react-redux";
import { history, store } from "../_helpers";
import { appActions, userActions } from "../_actions";
import { confService, userService } from "../_services";
import "../css/style.css";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { LANG_LIST } from "../_constants";

import i18next from "i18next";
import { Button, Collapse } from "@material-ui/core";
import TextFieldI18N from "../_components/TextFieldI18N";
import { user } from "../_reducers/user.reducer";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

const vItemTypes = ["radio", "checkbox", "text"];

function objToList(items, items_18n) {
  const itemsList = [];

  for (let k of Object.keys(items)) {
    itemsList.push({
      label: items[k],
      id: k,
      label_i18n:
        !items_18n[k] || items_18n[k].length == 0
          ? { en: "", cn: "", ru: items[k] }
          : items_18n[k],
    });
  }

  return itemsList;
}

const VoteItem = (props) => {
  const [isOpen, setOpen] = React.useState(true);
  const [itemType, setType] = React.useState(props.type);

  console.log(props);

  useEffect(() => {
    setType(props.type);
  }, [props.type]);

  /*
  {props.ind == props.editIndex && props.type == "text" && (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={props.is_numeric}
                  onClick={props.changeIsNumeric}
                />
              }
              label={i18next.t("is_numeric")}
            />
          </FormGroup>
        )}
          */

  return (
    <>
      <div
        className="title-small"
        style={{ margin: 0, display: "flex", flexDirection: "row", flex:1 }}
      >
        <div onClick={() => setOpen(!isOpen)}>
          {isOpen ? (
            <ExpandLess fontSize="large" />
          ) : (
            <ExpandMore fontSize="large" />
          )}
        </div>
        {props.editIndex < 0 && !props.hasAnwsers && (
          <Button variant="outlined" onClick={props.setEditIndex}>
            {i18next.t("btn_edit")}
          </Button>
        )}
        {props.editIndex < 0 && (
          <Button
            variant="outlined"
            style={{ marginLeft: 10 }}
            onClick={() => props.moveItem(props.ind, -1)}
          >
            {i18next.t("move_up")}
          </Button>
        )}
        {props.editIndex < 0 && (
          <Button
            variant="outlined"
            style={{ marginLeft: 10 }}
            onClick={() => props.moveItem(props.ind, 1)}
          >
            {i18next.t("move_down")}
          </Button>
        )}
        {props.ind == props.editIndex && (
          <div
            style={{
              marginLeft: 10,
              marginRight: 10,
              maxWidth: 700,
              fontSize: 16,
            }}
          >
            <TextFieldI18N
              autoFocus
              margin="dense"
              id="name"
              label={"название"}
              variant="standard"
              value={props.isMultilang ? props.tmpTextI18N : props.tmpText}
              onChange={(e, tabName) => props.changeTmpText(e, tabName)}
              langList={LANG_LIST}
              isMultilang={props.isMultilang}
              minWidth={600}
            />
          </div>
        )}
        { props.ind != props.editIndex && 
        <div style={{ marginLeft: 10, marginRight: 10, fontSize: 16 }}>
          {props.description}
        </div>}

        <div style={{ fontSize: 18, fontWeight: 700 }}>Тип: {itemType}</div>
      </div>
      <Collapse in={isOpen} timeout="auto" style={{ marginTop: 10 }}>
        <ul style={{ marginLeft: 30 }}>
          {props.ind != props.editIndex &&
          props.items.length > 0 &&
            props.items.map((optItem, index) => (
              <li style={{ marginTop: 5 }}>
                {index + 1}. {optItem.label}
              </li>
            ))}
        </ul>
        {props.ind == props.editIndex && (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={props.is_required}
                  onClick={props.changeRequired}
                />
              }
              label={i18next.t("is_required")}
            />
          </FormGroup>
        )}
        {props.ind == props.editIndex && props.type != "text" && (
          <div>
            <span>{i18next.t("options_list")}</span>
            <Button
              style={{ marginLeft: 10, marginBottom: 10 }}
              variant="outlined"
              onClick={props.addOptionsItem}
            >
              {i18next.t("add_object")}
            </Button>
            {props.tmpItems.map((item, index) => (
              <div
                style={{ display: "flex", flexDirection: "row", marginTop: 10 }}
              >
                <div>
                  <span>{index + 1}.</span>
                </div>
                <div style={{ marginLeft: 10 }}>
                  <Button
                    variant="outlined"
                    onClick={() => props.delOptionsItem(index)}
                  >
                    {i18next.t("del_btn")}
                  </Button>
                  <Button
                    variant="outlined"
                    style={{ marginLeft: 10 }}
                    onClick={() => props.moveOptionsItem(index, -1)}
                  >
                    {i18next.t("move_up")}
                  </Button>
                  <Button
                    variant="outlined"
                    style={{ marginLeft: 10 }}
                    onClick={() => props.moveOptionsItem(index, 1)}
                  >
                    {i18next.t("move_down")}
                  </Button>
                </div>
                <div style={{ maxWidth: 600, marginLeft: 20 }}>
                  <TextFieldI18N
                    autoFocus
                    margin="dense"
                    id="name"
                    label={i18next.t("btn_title")}
                    variant="standard"
                    value={props.isMultilang ? item.label_i18n : item.label}
                    onChange={(e, tabName) =>
                      props.editOptionsItem(e, index, tabName)
                    }
                    langList={LANG_LIST}
                    isMultilang={props.isMultilang}
                    style={{maxWidth:600}}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
        <FormControl>
          {props.ind == props.editIndex && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              <Button variant="outlined" onClick={props.saveItem}>
              {i18next.t("btn_save")}
              </Button>
              <Button
                variant="outlined"
                style={{ marginLeft: 10 }}
                onClick={props.cancelItem}
              >
                {i18next.t("btn_cancel")}
              </Button>
              <Button
                variant="outlined"
                style={{ marginLeft: 10 }}
                onClick={props.deleteItem}
              >
                {i18next.t("btn_delete")}
              </Button>
            </div>
          )}
        </FormControl>
      </Collapse>
    </>
  );
};

class CreateVotePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "one",
      id: 0,
      link: 0,
      is_anonymous: 0,
      need_auth: 0,
      langList: ["ru"],
      allLang: {
        ru: i18next.t("lang_ru"),
        en: i18next.t("lang_en"),
        cn: i18next.t("lang_cn"),
      },
      title_i18n: {},
      title: "",
      questions: [],
      tmpItems: [],
      tmpText: "",
      tmpTextI18N: { ru: "", en: "", cn: "" },
      editIndex: -1,
      is_numeric: 0,
      required: 0,
      isMultilang: false,
      langList: LANG_LIST,
    };
  }

  clickLangItem = (item) => {
    let { langList } = this.state;
    if (langList.indexOf(item) >= 0) {
      if (item != "ru") langList = langList.filter((e) => e != item);
    } else {
      langList.push(item);
    }

    this.setState({ langList });
  };

  storeChange = () => {
    const { dispatch } = this.props;

    if (store.getState().user.isLoading === false) {
      if (store.getState().user.error === "") {
        //dispatch(appActions.setpage("conf_list"));
      }
    }
  };

  componentDidMount() {
    let id = 0;

    if (window.location.href.indexOf("newvote") < 0) {
      let urls = window.location.href.split("/");
      id = urls[urls.length - 1];
    }

    const link = localStorage.getItem("navic_votelink");
    const need_auth = localStorage.getItem("navic_voteauth");
    const is_anonymous = localStorage.getItem("navic_voteanon");
    this.setState({
      lang: this.props.app.lang,
      id,
      link,
      need_auth,
      is_anonymous,
    });

    if (id > 0) this.getVoteInfo(id, link);
  }

  componentWillReceiveProps(nextProps) {
    const { selectedConf } = this.props.user;

    //this.setState({ lang: this.props.app.lang });
    if (nextProps.app.lang !== this.state.lang) {
      this.setState({ lang: nextProps.app.lang });
    }
  }

  setConfToState(confData) {
    let langList = confData.languages;

    if (!langList) langList = ["ru"];
  }

  voteDataToState = (data) => {
    const { header_i18n, header, questions, isMultilang, has_answers } =
      data.data;
    const questions_list = [];

    console.log(questions);

    for (let key in questions) {
      const listItems = objToList(
        questions[key].items ?? {},
        questions[key].items_i18n ?? {}
      );

      questions_list.push({
        ...questions[key],
        id: key,
        items: listItems,
      });
    }

    this.setState({
      title_i18n: isMultilang
        ? header_i18n
        : { ru: header, en: header, cn: header },
      title: header,
      hasAnwsers: has_answers,

      questions: questions_list.sort((a, b) => a.order - b.order),
    });
  };

  getVoteInfo = (id, link) => {
    const self = this;
    const { dispatch } = this.props;

    if (id == 0) return;

    userService.getWrapper(
      "api/vote/main?id=" + id + "&signature=" + link,
      (data) => {
        if (data.status === 200) {
          console.log(data);
          self.voteDataToState(data);
        }
      },
      (error) => {
        dispatch(userActions.set_error(i18next.t("cant_execute_request")));
      }
    );
  };

  onAdd = () => {};

  onSave = () => {};

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeI18N = (event, tabName) => {
    let tmpObj = this.state[event.target.name] ?? {};
    tmpObj = { ...tmpObj };
    tmpObj[tabName] = event.target.value;
    this.setState({ [event.target.name]: tmpObj });
  };

  changeRequired = () => {
    let { required } = this.state;
    if (!required) required = 0;
    this.setState({ required: 1 - required });
  };

  changeIsNumeric = () => {
    let { is_numeric } = this.state;
    if (!is_numeric) is_numeric = 0;
    this.setState({ is_numeric: 1 - is_numeric });
  };

  changeTmpText = (e, tabName) => {
    const { isMultilang } = this.state;
    if (isMultilang) {
      let { tmpText, tmpTextI18N } = this.state;
      tmpTextI18N[tabName] = e.target.value;
      tmpText = tmpTextI18N["ru"];
      console.log(tmpTextI18N);

      this.setState({ tmpText, tmpTextI18N });
    } else {
      this.setState({ tmpText: e.target.value });
    }
  };

  addItemRadio = () => {
    let { questions, editIndex } = this.state;

    if (editIndex >= 0) {
      alert(i18next.t("exit_editing_mode"));
      return;
    }

    questions.push({
      description: "",
      description_i18n: { ru: "", en: "", cn: "" },
      type: "radio",
      items: [],
      order: questions.length,
    });
    this.setState({ questions });
    let newIndex = questions.length - 1;
    this.setEditIndex(questions[newIndex], newIndex);
  };

  addItemCheckbox = () => {
    let { questions, editIndex } = this.state;

    if (editIndex >= 0) {
      alert(i18next.t("exit_editing_mode"));
      return;
    }

    questions.push({
      description: "",
      description_i18n: { ru: "", en: "", cn: "" },
      type: "checkbox",
      items: [],
      order: questions.length,
    });
    this.setState({ questions });
    let newIndex = questions.length - 1;
    this.setEditIndex(questions[newIndex], newIndex);
  };

  addItemText = () => {
    let { questions, editIndex } = this.state;

    if (editIndex >= 0) {
      alert(i18next.t("exit_editing_mode"));
      return;
    }

    questions.push({
      description: "",
      description_i18n: { ru: "", en: "", cn: "" },
      type: "text",
      items: [],
      order: questions.length,
    });
    this.setState({ questions });
    let newIndex = questions.length - 1;
    this.setEditIndex(questions[newIndex], newIndex);
  };

  changeItemType = (tp, ind) => {
    console.log(tp, ind);

    let { questions } = this.state;
    questions[ind].type = tp;

    this.setState({ questions });
  };

  saveItem = () => {
    let self = this;
    const {
      editIndex,
      questions,
      tmpItems,
      tmpText,
      tmpTextI18N,
      required,
      is_numeric,
    } = this.state;

    const { type, id, order } = questions[editIndex];

    const questData = [];
    //if (id > 0) questData.push({ action: "delete", id: id });

    let tmpObj = {
      action: id > 0 ? "update" : "insert",
      type: type,
      description: tmpText,
      description_i18n: tmpTextI18N,
      required: required,
      order: order,
      items: tmpItems.map(function (item) {
        return {
          action: "insert",
          label: item.label,
          label_i18n: item.label_i18n,
        };
      }),
    };

    if (id > 0) tmpObj.id = id;

    if (type == "text") tmpObj.is_numeric = is_numeric;

    questData.push(tmpObj);
    this.saveData(questData);

    this.setState({
      editIndex: -1,
      tmpItems: [],
      tmpText: "",
      tmpTextI18N: { ru: "", en: "", cn: "" },
    });

    //self.getVoteInfo(id, link);
  };

  deleteItem = () => {
    const { editIndex, questions } = this.state;

    const editItem = questions[editIndex];

    if (editItem && !editItem.id) {
      this.cancelItem();
      return;
    }

    const { id } = questions[editIndex];

    const questData = [];
    if (id > 0) questData.push({ action: "delete", id: id });

    if (window.confirm(i18next.t("del_object")+"?")) {
      this.saveData(questData);

      this.setState({
        editIndex: -1,
        tmpItems: [],
        tmpText: "",
        tmpTextI18N: { ru: "", en: "", cn: "" },
      });
    }
  };

  cancelItem = () => {
    const { editIndex, questions, id } = this.state;

    this.setState({
      editIndex: -1,
      tmpItems: [],
      tmpText: "",
      tmpTextI18N: { ru: "", en: "", cn: "" },
      questions: [...questions],
    });

    //this.getVoteInfo(id);
  };

  saveData = (questData) => {
    const {
      id,
      title_i18n,
      title,
      is_anonymous,
      need_auth,
      link,
      isMultilang,
    } = this.state;

    console.log(title, title_i18n, isMultilang);

    let self = this;

    let postData = {};

    //if (isMultilang)
    postData = { header_i18n: title_i18n, header: title };
    //else postData = { header: title };

    if (questData) {
      postData = { questions: [...questData] };
    }

    let apiUrl = "api/vote?";

    if (id > 0) apiUrl += "id=" + id;

    if (!questData) {
      if (id > 0) apiUrl = apiUrl + "&";
      apiUrl =
        apiUrl + "is_anonymous=" + is_anonymous + "&need_auth=" + need_auth;
    }

    apiUrl = apiUrl + "&rewrite_mode=1";

    console.log(postData);

    userService.postWrapper(
      apiUrl,
      postData,
      (data) => {
        console.log(data);
        if (data.data.id > 0) {
          self.setState({ id: data.data.id });
          //self.getVoteInfo(data.data.id);
          self.voteDataToState(data);
          localStorage.setItem("navic_voteauth", need_auth);
          localStorage.setItem("navic_voteanon", is_anonymous);
        }
      },
      (error) => {
        if (error != "") {
          self.props.dispatch(userActions.set_error(error));
          //self.getVoteInfo(id,link);
        }
      }
    );
  };

  saveTitle = () => {
    const {
      id,
      title_i18n,
      title,
      is_anonymous,
      need_auth,
      link,
      isMultilang,
    } = this.state;

    if (
      (isMultilang &&
        !title_i18n["ru"] &&
        !title_i18n["en"] &&
        !title_i18n["cn"] &&
        !title) ||
      (!isMultilang && !title)
    ) {
      alert(i18next.t("enter_vote_name"));
      return 0;
    }

    this.saveData();
  };

  delVote = () => {
    const { id } = this.state;

    let self = this;
    if (id < 1) return;

    if (window.confirm(i18next.t("del_vote")+"?")) {
      let apiUrl = "api/vote/" + id;

      userService.delWrapper(
        apiUrl,
        () => {
          history.push("/votelist");
        },
        (error) => {
          if (error != "") {
            self.props.dispatch(userActions.set_error(error));
          }
        }
      );
    }
  };

  setEditIndex = (item, index) => {
    console.log(item);

    this.setState({
      editIndex: index,
      tmpItems: [...item.items],
      oldItems: [...item.items],
      tmpText: item.description,
      tmpTextI18N: item.description_i18n ?? {
        ru: item.description,
        en: "",
        cn: "",
      },
      required: item.required,
      is_numeric: item.is_numeric,
    });
  };

  editOptionsItem = (e, ind, tabName) => {
    const { isMultilang, tmpItems } = this.state;

    let { label_i18n } = tmpItems[ind];

    if (isMultilang) {
      tmpItems[ind].label_i18n[tabName] = e.target.value;
      tmpItems[ind].label = label_i18n["ru"];
    } else {
      tmpItems[ind].label = e.target.value;
    }

    this.setState({ tmpItems: [...tmpItems] });
  };

  moveItem = (ind, shift) => {
    const { questions, hasAnwsers } = this.state;

    if (hasAnwsers) return;

    const tmpItem = { ...questions[ind] };

    let isOk = false;

    if (ind > 0 && shift < 0) {
      questions.splice(ind, 1);
      questions.splice(ind - 1, 0, tmpItem);
      isOk = true;
    }

    if (ind < questions.length - 1 && shift > 0) {
      questions.splice(ind, 1);
      questions.splice(ind + 1, 0, tmpItem);
      isOk = true;
    }

    if (!isOk) return;

    let questData = [];

    for (let i = 0; i < questions.length; i++) {
      const tmpData = {
        action: "update",
        id: questions[i].id,
        order: i,
        //items: [...questions[i].items],
      };

      questData.push(tmpData);
    }

    this.saveData(questData);
  };

  addOptionsItem = () => {
    const { tmpItems } = this.state;
    tmpItems.push({ id: 0, label: "", label_i18n: { ru: "", en: "", cn: "" } });
    this.setState({ tmpItems: [...tmpItems] });
  };

  delOptionsItem = (ind) => {
    if (window.confirm(i18next.t("del_object")+"?")) {
      const { tmpItems } = this.state;
      tmpItems.splice(ind, 1);
      this.setState({ tmpItems });
    }
  };

  moveOptionsItem = (ind, shift) => {
    const { tmpItems } = this.state;
    const tmpItem = { ...tmpItems[ind] };
    if (ind > 0 && shift < 0) {
      tmpItems.splice(ind, 1);
      tmpItems.splice(ind - 1, 0, tmpItem);
    }

    if (ind < tmpItems.length - 1 && shift > 0) {
      tmpItems.splice(ind, 1);
      tmpItems.splice(ind + 1, 0, tmpItem);
    }

    this.setState({ tmpItems });
  };

  render() {
    const self = this;
    const {
      id,
      title_i18n,
      title,
      is_anonymous,
      need_auth,
      questions,
      editIndex,
      tmpItems,
      tmpText,
      tmpTextI18N,
      required,
      is_numeric,
      isMultilang,
      hasAnwsers,
    } = this.state;

    console.log(questions);

    return (
      <div className="layout__wrap">
        <h1>
          {id == 0 ? i18next.t("create_vode") : i18next.t("edit_vode")}
        </h1>
        {hasAnwsers && (
          <h3 style={{ marginTop: 10 }}>
            {i18next.t("cant_edit_vote")}
          </h3>
        )}
        <TextFieldI18N
          autoFocus
          margin="dense"
          id="name"
          label={i18next.t("name")}
          fullWidth
          variant="standard"
          value={isMultilang ? title_i18n : title}
          disabled={hasAnwsers}
          onChange={(e, tabName) => {
            if (isMultilang) {
              let tmpI18N = title_i18n;
              tmpI18N[tabName] = e.target.value;

              self.setState({ title_i18n: tmpI18N, title: e.target.value });
            } else {
              const title = e.target.value;
              self.setState({
                title,
                title_i18n: { en: title, ru: title, cn: title },
              });
            }
          }}
          langList={LANG_LIST}
          isMultilang={isMultilang}
        />

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                color="default"
                checked={need_auth == 1}
                onClick={() => {
                  if (need_auth == 1)
                    self.setState({ need_auth: 0, is_anonymous: 0 });
                  else self.setState({ need_auth: 1 });
                }}
                disabled={hasAnwsers}
              />
            }
            label={i18next.t("need_auth")}
          />
          <FormControlLabel
            control={
              <Checkbox
                color="default"
                checked={is_anonymous == 1}
                onClick={() =>
                  self.setState({ is_anonymous: 1 - is_anonymous })
                }
                disabled={hasAnwsers || need_auth == 0}
              />
            }
            label={i18next.t("is_anonymos")}
          />
        </FormGroup>
        {!hasAnwsers && (
          <div className="layout__btn" style={{ marginTop: 10 }}>
            <button className="btn" onClick={self.saveTitle}>
              {i18next.t("btn_save")}
            </button>
          </div>
        )}
        {id > 0 && (
          <div className="layout__btn" style={{ marginTop: 10 }}>
            <button className="btn" onClick={self.delVote}>
              {i18next.t("btn_delete")}
            </button>
          </div>
        )}
        {id > 0 && (
          <div style={{ marginTop: 10 }}>
            <h3 style={{ marginBottom: 10 }}>{i18next.t("vote_items")}</h3>
            {!hasAnwsers && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 10,
                }}
              >
                <Button
                  variant="outlined"
                  style={{ marginRight: 10 }}
                  onClick={self.addItemRadio}
                >
                  {i18next.t("vote_radio")}
                </Button>
                <Button
                  variant="outlined"
                  style={{ marginRight: 10 }}
                  onClick={self.addItemCheckbox}
                >
                  {i18next.t("vote_checkbox")}
                </Button>
                <Button
                  variant="outlined"
                  style={{ marginRight: 10 }}
                  onClick={self.addItemText}
                >
                  {i18next.t("vote_text")}
                </Button>
              </div>
            )}
            {questions.length > 0 &&
              questions.map((item, index) => (
                <VoteItem
                  description={item.description}
                  items={item.items}
                  id={item.id}
                  type={item.type}
                  ind={index}
                  editIndex={editIndex}
                  setEditIndex={() => self.setEditIndex(item, index)}
                  tmpItems={tmpItems}
                  tmpText={tmpText}
                  tmpTextI18N={tmpTextI18N}
                  changeTmpText={self.changeTmpText}
                  saveItem={self.saveItem}
                  cancelItem={self.cancelItem}
                  deleteItem={self.deleteItem}
                  addOptionsItem={self.addOptionsItem}
                  delOptionsItem={self.delOptionsItem}
                  editOptionsItem={self.editOptionsItem}
                  moveOptionsItem={self.moveOptionsItem}
                  moveItem={self.moveItem}
                  changeRequired={self.changeRequired}
                  required={required}
                  changeIsNumeric={self.changeIsNumeric}
                  is_numeric={is_numeric}
                  is_required={required}
                  isMultilang={isMultilang}
                  hasAnwsers={hasAnwsers}
                />
              ))}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;

  return {
    user,
    app,
    authentication,
  };
}

const connectedCreateVotePage = connect(mapStateToProps)(CreateVotePage);
export { connectedCreateVotePage as CreateVotePage };
