import React from "react";
import "../css/style.css";
import i18next from "i18next";
import { connect } from "react-redux";
import { store } from "../_helpers";
import { userService } from "../_services";

import { userActions } from "../_actions";
import { EntityPage } from "../_components/EntityPage/EntityPage";

class UserListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      userList: [],
    };
    store.subscribe(this.storeChange);
  }

  getUsers = () => {
    let self = this;

    userService.getWrapper(
      "api/superuser/users",
      (data) => {
        console.log(data);
        if (data.data) {
          self.setState({ userList: [...data.data], isLoading: false });
        }
      },
      (error) => {
        if (error != "") {
          self.setState({ error, isLoading: false });
          self.props.dispatch(userActions.set_error(error));
        }
      }
    );
  };

  memberLink = data => {
    const ownerLink = "owner-" + data.id;
    if (data.type === "team_member") {
      const ownerDest = "#owner-" + data.owner_id;
      return <a name={ownerLink} href={ownerDest}>{i18next.t(data.type)} ({data.owner_id})</a>;
    } else {
      return <a name={ownerLink}>{i18next.t(data.type)}</a>;
    }
  };

  storeChange = (e) => {};

  render() {
    const self = this;
    const {
      isLoading,
      userList,
    } = this.state;

    return (
      <EntityPage
        loader={this.getUsers}
        header={{
          title: i18next.t("userlist"),
        }}
        list={{
          entities: this.state.userList,
          classes: { row: (item) => `UserListPage_row` },
          fields: [
            {
              name: "id",
              class: "tbl__item--text",
              head: "id",
            },
            {
              name: "email",
              class: "tbl__item--text",
              head: "email",
            },
            {
              name: "email_verified_at",
              class: "tbl__item--text",
              head: i18next.t("email_verified_at"),
            },
            {
              name: "type",
              class: "tbl__item--text",
              head: i18next.t("type"),
              convertor: this.memberLink
            },
            {
              name: "timezone",
              class: "tbl__item--text",
              head: i18next.t("timezone"),
            },
            {
              name: "locale",
              class: "tbl__item--text",
              head: i18next.t("locale"),
            },
            {
              name: "balance",
              class: "tbl__item--text",
              head: i18next.t("balance"),
            },
          ],
          actions: (item) => ["login"],
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;

  return {
    user,
    app,
    authentication,
  };
}

const connectedUserListPage = connect(mapStateToProps)(UserListPage);
export { connectedUserListPage as UserListPage };
