import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";

import "../css/style.css";

export default function BookDialog(props) {
  const [data, setData] = React.useState({
    start_time: "",
    end_time: "",
    start_date: "",
    end_date: "",
  });
  const [count, setCount] = React.useState(0);

  const { handleClose, dialogOpen } = props;

  function cancelData() {
    handleClose(null);
    setCount(0);
    setData({ start_time: "", end_time: "", start_date: "", end_date: "" });
  }

  function saveData() {
    if (data.start_time && data.start_date && data.end_time && data.end_date) {
      handleClose({ ...data });
      setData({ start_time: "", end_time: "", start_date: "", end_date: "" });
    } else {
      alert("Заполните время начала и окончания");
    }
  }

  function setInfo(name, val) {
    let tmpData = { ...data };
    tmpData[name] = val;
    setData(tmpData);
  }

  return (
    <Dialog open={dialogOpen}>
      <DialogTitle>Запрос на бронирование площадки</DialogTitle>
      <DialogContent>
        <div style={{ fontSize: 16 }}>Помещение: {props.number}</div>
        <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
          <div style={{ fontSize: 16, minWidth: 150, paddingTop: 15 }}>
            Начало аренды
          </div>
          {data && (
            <input
              style={{ marginLeft: 10, marginRight: 10 }}
              type="date"
              className="form__input"
              id="start"
              value={data.start_date ? data.start_date : ""}
              name="start_date"
              onChange={(e) => setInfo("start_date", e.target.value)}
            />
          )}
          {data && (
            <input
              style={{ marginLeft: 10, marginRight: 10 }}
              type="time"
              className="form__input"
              id="start"
              value={data.start_time ? data.start_time : ""}
              name="start_time"
              onChange={(e) => setInfo("start_time", e.target.value)}
            />
          )}
        </div>
        <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
          <div style={{ fontSize: 16, minWidth: 150, paddingTop: 15 }}>
            Окончание аренды
          </div>
          {data && (
            <input
              style={{ marginLeft: 10, marginRight: 10 }}
              type="date"
              className="form__input"
              id="start"
              value={data.end_date ? data.end_date : ""}
              name="end_date"
              onChange={(e) => setInfo("end_date", e.target.value)}
            />
          )}
          {data && (
            <input
              style={{ marginLeft: 10, marginRight: 10 }}
              type="time"
              className="form__input"
              id="start"
              value={data.end_time ? data.end_time : ""}
              name="end_time"
              onChange={(e) => setInfo("end_time", e.target.value)}
            />
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={saveData}>
          Отправить запрос на площадку
        </Button>
        <Button onClick={cancelData}>Отмена</Button>
      </DialogActions>
    </Dialog>
  );
}
