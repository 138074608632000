import React from "react";
import PropTypes from "prop-types";
import { history, store } from "../_helpers";
import "../css/style.css";
import "../css/grid.css";
import { storeData, setStore } from "../store.js";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import RoomRentItem  from './RoomRentItem';
import BookDialog  from './BookDialog';
import Rating from '@mui/material/Rating';
import { userActions, appActions } from "../_actions";
import i18next from "i18next";

import { userService, confService } from "../_services";

import { serverUrl } from "../_constants";

import confLogo from "../img/conf_logo.png";

const nodata_text = "уточняется";


class RentViewPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPublic: false,
      isErr: "",
      isLoading: false,
      addr: "",
      sponsors: [],
      scheduleData: [],
      schFav: [],
      hasTicket: false,
      oneDaySchedule: [],
      curDateIndex: 0,
      table_class: "col-md-4",
      tickets: [],
      openTicket: false,
      slotInfoOpen: false,
      speakerInfoOpen: false,
      sponsorInfoOpen: false,
      slotData: {},
      speakerData: {},
      sponsorData: {},
      favChedule: [],
      blds: [],
      id: 0,
      isUrl: false,
      urlt: "",
      showInfoDialog: false,
	  showQRDialog: false,
      needFields: [],
      oneColumn: true,
      speakers:[],
      isMobile: false,
	  speakersOpen: false,
	  openQR: false,
	  ticketData: null,
	  ticketsInfo: [],
	  qrData: '',
	  rooms_data: [],
	  roomOpenState: {},
	  showBookDialog: false,
    feedback: [],
    reviewStars:5,
    reviewText:'',
	};
    
  }

  componentDidMount() {
    
	
    let lang = "ru";
    
     i18next.init({
      lng: lang,
      resources: require(`../_lang/${lang}.json`),
    });
	
	

    const { selectedSite, info } = this.props.user;
	
	console.log(selectedSite);
	
	if (!selectedSite) history.push('/');

	 let img_url = '';
  
  if  (selectedSite.documents)
  {
	  let img_data = selectedSite.documents[selectedSite.documents.length-1];
	  if (img_data)
		  img_url = img_data.path;
  }
  	
	
	
	this.setState({id:selectedSite.id,name:selectedSite.name,description:selectedSite.description,event_types:selectedSite.event_types,address:selectedSite.address,img_url});
    
    this.setState({isMobile:window.screen.width<600});
    
	//this.getSiteInfo(selectedSite.id);
	this.getRoomsList(selectedSite.id);
  this.getReviews();
  
  
    
  }

  getReviews = () => {

    const { selectedSite, info } = this.props.user;
    let self = this;

    userService.getWrapper(
      `api/feedback?entity_type=building&entity_id=${selectedSite.id}`,
      (data) => {
        if (data.data) {
        self.setState({feedback:[...data.data]});
     }
    
      },
      (error) => {
        if (error != "") {
          console.log(error);
        }
      }
    );
  
  }
  
  closeBookDialog = (data) => {
	  
	  console.log(data);
	  let self = this;
	  
	  if (data)
	  {
		  userService.postWrapper(
        'api/booking?room_id='+this.state.roomId+"&start_time="+data.start_date+" "+data.start_time+":00&end_time="+data.end_date+" "+data.end_time+":00",
        {},
        function (data) {
            console.log(data);
          alert('Запрос успешно отправлен');
          
        },
        function (error) {
          if (error != "") {
            self.setState({ error: error});
			alert('Да данное время нет свободных мест');
          }
        }
      ); 
	  
		  
	  }
	  
	  this.setState({roomId:null,showBookDialog:false});
  }
  
  clickExpand = (index) => {
	  
	  let { roomOpenState } = this.state;
	  
	  if (!roomOpenState[index+''])
		  roomOpenState[index+''] = false;
	  
	  roomOpenState[index+''] = !roomOpenState[index+''];
	  
	  
	  console.log(roomOpenState);
	  
	  
	  this.setState({roomOpenState});
  }
  
  getSiteInfo = (id, is_save = false) => {
    const self = this;
    const { classes, dispatch } = this.props;

    confService.siteInfo(
      id,
      (data) => {
        if (data.status == 200) {
			
		  let confData = data.data;

          this.setState({
				  id: confData.id,
				  name: confData.name,
				  comment: confData.description,
				  address: confData.address,
				});
        }
      },
      (error) => {
        dispatch(userActions.set_error(error));
      }
    );
  };
  
  getRoomsList = (id) => {
		
		
		const { classes, dispatch } = this.props;
		let self = this;
		
		if (!id)
			return;
		
		userService.getWrapper(
        `api/room-all?building_id=${id}`,
        (data) => {
          if (data.data) {
            let tmp_data = data.data.map(item=>item.id);
			self.getRoomsData(tmp_data);
		  }
		  
		  
        },
        (error) => {
          if (error != "") {
            dispatch(userActions.set_error(error));
          }
        }
      );
	}

	getRoomsData = (ids) => {
		
		let self = this;
		let urls = [];
		    for (let i = 0; i < ids.length; i++)
					urls.push(`api/room/${ids[i]}`);

        userService.getAsync(
          urls,
          function (data) {
			  
			  let tmp_data = data.map(item=>item.data);
			  console.log(tmp_data);
			  self.setState({rooms_data:tmp_data});
		  },  
		  function (err) {
            console.log(err);
          }
        );

		
	}

  
  bookRoom = (index) => {
		
		this.setState({showBookDialog:true,roomId:this.state.rooms_data[index].id,roomName:this.state.rooms_data[index].number});
  }
  
  sendQ = () => {
      
      let self = this;
    const { reviewStars,  reviewText} = this.state;
    const { selectedSite, info } = this.props.user;

  userService.postWrapper(
    `api/feedback?entity_type=building&entity_id=${selectedSite.id}&score=${reviewStars}&report=${reviewText}`,
    (data) => {
      self.setState({reviewStars:5,reviewText:''});
      self.getReviews();
      alert('Отзыв отправлен');
  
    },
    (error) => {
      if (error != "") {
        console.log(error);
      }
    }
  );
    
  }

  
  
  
 
  render() {
      
    const self = this;
    const {
      id,
      image,
      description,
      name,
      start_time,
      end_time,
      address,
      feedback,
      isMobile,
	  rooms_data,
	  roomOpenState,
	  showBookDialog,
	  roomId,
	  roomName,
	  img_url,
    reviewStars,
    reviewText,
	} = this.state;

    const { classes, user, schedule } = this.props;
    
    return (
      <div className="layout" style={{width:'100%',overflowX:'hidden'}}>
        
        
          <div>
		  <section className="member-top">
            
            
              <div className="conf-detail conf-detail-member">
			  {!isMobile && <div className="conf-img">
                  <img src={img_url ? serverUrl + img_url : confLogo} alt="" />
			  </div>}
                <div className="conf-info">
                  <div className="title h1">{name}</div>
                  
                  <div className="meta">
                    <div className="address">
                      <span>{address}</span>
                    </div>
                  </div>
                 
                  
                 
                </div>
				
              </div>
            </section>
			<h3>Помещения</h3>
        
		{rooms_data.length>0 && rooms_data.map(function(item,index){
						  
						  
						  return (<RoomRentItem 
						  number={item.number} 
						  description={item.description} 
						  equipment={item.equipment}
						  seats={item.seats}
						  index={index}
						  time_from={(item.schedule && item.schedule.base && item.schedule.base.hours && item.schedule.base.hours.from)?item.schedule.base.hours.from:''}
						  time_to={(item.schedule && item.schedule.base && item.schedule.base.hours && item.schedule.base.hours.to)?item.schedule.base.hours.to:''}
						  price_hour={(item.schedule && item.schedule.base && item.schedule.base.cost)?item.schedule.base.cost:''}
						price_day={(item.schedule && item.schedule.base  && item.schedule.base['daycost-for-hourrooms'])?item.schedule.base['daycost-for-hourrooms']:''}	
						  
						  bookRoom={self.bookRoom}
						  clickExpand={self.clickExpand}
						  isClosed={self.state.roomOpenState[index+'']}
						  />);
					  })}


            <h3>Отзывы</h3>
            {feedback.length>0 && feedback.map(item=>(<div style={{marginTop:5}}>Оценка: {item.score}, Отзыв: {item.report?item.report:'нет текста'}</div>))}

            <h3 style={{marginTop:20,marginBottom:10}}>Оставить отзыв</h3>
            <div>
            <Rating
  name="simple-controlled"
  value={reviewStars}
  onChange={(event, newValue) => {
    self.setState({reviewStars:newValue})
  }}
/>
<textarea
                      id="area1"
                      className="form__input"
                      placeholder="Текст"
                      name="q"
					          multiline
                      onChange={(e)=>self.setState({reviewText:e.target.value})}
                      value={reviewText}
                      style={{marginTop:5}}
                    />
              <Button onClick={self.sendQ}>Отправить отзыв</Button>
            </div>
		
          </div>
        
	        <BookDialog
          handleClose={self.closeBookDialog}
          dialogOpen={showBookDialog}
		  number={roomName}
        />
				
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication, schedule } = state;

  return {
    user,
    app,
    authentication,
    schedule,
  };
}

const connectedRentViewPage = connect(mapStateToProps)(RentViewPage);
export { connectedRentViewPage as RentViewPage };
