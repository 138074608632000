import React from "react";
import "../css/style.css";
import Button from "@mui/material/Button";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';


export default function RoomRentItem(props) {

  const { number, description,equipment,seats, time_from, time_to, price_hour, price_day, index, isClosed } = props;
 
  return (
     <div style={{display:'flex',flex:1,marginBottom:10}}>
		<div className="form__col">
				<div className="form__group">
					 <div style={{display:'flex',flex:1}}>
				  <div style={{marginRight:5, fontSize:20}}>{index+1}.</div>
				<div className="title-small" onClick={()=>props.clickExpand(index)}>
				  	{!isClosed ? <ExpandLess  fontSize="medium"/> : 
									<ExpandMore  fontSize="medium"/>}
					</div>
                    <span>
					{number}
					</span>
                    </div>
				 
				</div>
                  
				  
				  <Collapse in={!isClosed} timeout="auto" >
				 <div className="form__group">
					<div>Описание помещения: {description?description:'нет данных'}</div>
				
				  <div style={{marginTop:5}}>Оборудование: {equipment?equipment:'нет данныx'}</div>
				    <div style={{marginTop:5}}>Мест в помещении: {seats?seats:'нет данныx'}</div>
				  </div>
				  
				  <div className="form__group">
				  <div style={{fontSize:16, fontWeight:800}}>Время и стоимость аренды</div>
				  <div style={{display:'flex',flex:1, marginTop:10}}>
				  <div style={{marginRight:5,minWidth:200, paddingTop:10}}>Время начала: {time_from?time_from:'нет данных'}</div>
                  </div>
					<div style={{display:'flex',flex:1}}>
					<div style={{marginRight:5,minWidth:200, paddingTop:10}}>Время окончания: {time_to?time_to:'нет данных'}</div>
                    </div>
					<div style={{display:'flex',flex:1}}>
					<div style={{marginRight:5,minWidth:200, paddingTop:10}}>Цена за час: {price_hour?price_hour:'нет данных'}</div>
                    </div>
					<div style={{display:'flex',flex:1}}>
					<div style={{marginRight:5,minWidth:200, paddingTop:10}}>Цена за сутки: {price_day?price_day:'нет данных'}</div>
                    </div>
				  </div>
				  <div style={{display:'flex',flex:1,marginBottom:20}}>
					<Button style={{marginRight:20}} variant="contained" onClick={()=>props.bookRoom(index)}>Забронировать</Button>
					</div>
				</Collapse>	
		</div>
	</div>
  );
}


