import * as React from 'react';
import Box from '@mui/material/Box';
import ConferenceAnketaList from './ConferenceAnketaList';
import i18next from 'i18next';

export default function ConferenceAnketa(props) {
  return (<Box>
    <div className="form__group">
      <h3>{i18next.t("conference_anketa_title")}</h3>
    </div>
    <div className="form__group">
      <div class="form__group-top">
        <span class="form__label">{i18next.t("required_anketa_fields")}</span>
      </div>
      <div class="form__group-body">
        <ConferenceAnketaList anketaKey="requiredAnketa" anketa={props.requiredAnketa} additionalAnketa={props.optionalAnketa} parentProps={props} />
      </div>
    </div>
    <div className="form__group">
      <div class="form__group-top">
        <span class="form__label">{i18next.t("optional_anketa_fields")}</span>
      </div>
      <div class="form__group-body">
        <ConferenceAnketaList anketaKey="optionalAnketa" anketa={props.optionalAnketa} additionalAnketa={props.requiredAnketa} parentProps={props} />
      </div>
    </div>
  </Box>);
}
